import React, { useState, useEffect } from "react";

const CountdownTimer = ({finishTime}) => {
  // Fecha y hora objetivo: Viernes 15 de noviembre a las 5:00 PM
  //const targetDate = new Date("2024-11-15T17:00:00");

  const targetDate = new Date("2024-11-15T17:00:00");

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Función para calcular el tiempo restante
  function calculateTimeLeft() {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
        finishTime(true);
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  // Actualiza el tiempo restante cada segundo
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Limpia el intervalo cuando el componente se desmonte
  }, []);

  return (
    <div style={styles.container}>
     <p style={{fontSize:'50px'}}>
     Estamos trabajando para poderte atender próximamente
     </p>
     {/* <p style={{fontSize:'50px'}}>
     15 de Noviembre a las 5:00 PM
     </p> */}
      {/* <div style={styles.timer}>
        <div style={styles.timeBox}>
          <p style={styles.number}>{timeLeft.days}</p>
          <span>Días</span>
        </div>
        <div style={styles.timeBox}>
          <p style={styles.number}>{timeLeft.hours}</p>
          <span>Horas</span>
        </div>
        <div style={styles.timeBox}>
          <p style={styles.number}>{timeLeft.minutes}</p>
          <span>Minutos</span>
        </div>
        <div style={styles.timeBox}>
          <p style={styles.number}>{timeLeft.seconds}</p>
          <span>Segundos</span>
        </div>
      </div> */}
      {timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0 && (
        <h2>¡La espera ha terminado!</h2>
      )}
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  timer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    marginTop: "20px",
  },
  timeBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  number: {
    fontSize: "2rem",
    fontWeight: "bold",
  },
};

export default CountdownTimer;
