import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

// Función para procesar los datos de ventas
const processSalesDataByDay = (salesData) => {
    const salesByDay = {};

    // Procesa los datos de ventas para acumular las ventas por día
    salesData.forEach(sale => {
        const date = new Date(sale.timestamp);
        const day = date.toISOString().split('T')[0]; // Formato YYYY-MM-DD

        // Calcula el total de la venta
        const totalSale = sale.items.reduce((total, item) => total + parseFloat(item.price) * (item.quantity || 1), 0);

        if (salesByDay[day]) {
            salesByDay[day] += totalSale;
        } else {
            salesByDay[day] = totalSale;
        }
    });

    // Ordena los días y prepara los datos para la gráfica
    const labels = Object.keys(salesByDay).sort();
    const data = labels.map(day => salesByDay[day]);

    return {
        labels,
        datasets: [
            {
                label: 'Total de Ventas por Día',
                data,
                fill: false,
                borderColor: '#36A2EB',
                backgroundColor: '#36A2EB',
                tension: 0.1,
            },
        ],
    };
};

const LineChart = ({ salesData }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        const chartData = processSalesDataByDay(salesData);
        if (chartRef.current) {
            chartInstanceRef.current = new Chart(chartRef.current, {
                type: 'line',
                data: chartData,
            });
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [salesData]);

    return (

        <canvas ref={chartRef} />

    );
};

export default LineChart;
