import {getFirestore, collection, getDocs, query, where } from 'firebase/firestore';

export const checkCBar = async (cbar) =>{
    const db = getFirestore();  

    try {
       
        const q = await getDocs(query(collection(db, "/products"), where("cbar", "==", cbar)));
        
        if(q.docs.length){
            return q.docs[0].data().cbar;
        }else{
            return false;
        }
        
     } catch (error) {
        
     }
}

export const getTotalOrder = (order) =>{
    return order.reduce((accumulator, item) => {
        const quantity = !item.quantity ? 1 : item.quantity;
        return accumulator + quantity * item.price;
    }, 0).toFixed(2)
}

export const isValueAllowed = (values) => {
    const { floatValue } = values;
    return floatValue === undefined || (floatValue >= 0 && floatValue <= 999999999.99);
};

export const applyDiscount = (amount, percent) => {

    if (percent < 0 || percent > 100) {
        return amount;
    }
    let discount = (amount * percent || 0) / 100;
    let finalAmount = amount - discount;
    return finalAmount;
}