import {
    getFirestore, collection, addDoc,
    getDocs, deleteDoc, doc, getDoc, updateDoc, query, where, setDoc, Timestamp
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import M from 'materialize-css';

export const addCategorie = async (dataCategorie) => {
    const db = getFirestore();

    try {
        await addDoc(collection(db, `/categories`), dataCategorie);
        M.toast({ html: "Información almacenada correctamente", classes: "green darken-4" });
        return true;
    } catch (error) {

        M.toast({ html: "Ocurrio un error al guardar la información." });
        return false;
    }
}

export const formatearTimestamp = (timestamp) => {
    // Convertir el Timestamp de Firebase a un objeto Date
    const date = timestamp.toDate();

    // Obtener los componentes de la fecha
    const dia = String(date.getDate()).padStart(2, '0');
    const mes = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van de 0-11
    const anio = date.getFullYear();

    // Obtener los componentes de la hora
    const horas = String(date.getHours()).padStart(2, '0');
    const minutos = String(date.getMinutes()).padStart(2, '0');

    // Formatear la fecha y la hora en 'DD/MM/YYYY HH:MM'
    return `${dia}/${mes}/${anio} ${horas}:${minutos}`;
}


export const updateCategorie = async (data, idCategorie) => {
    const db = getFirestore();

    try {
        await updateDoc(doc(db, '/categories', idCategorie), data)
        M.toast({ html: "Información actualizada correctamente", classes: "green darken-4" });
        return true;
    } catch (error) {

        M.toast({ html: "Ocurrio un error al actualizar la información." });
        return false;
    }
}


export const updateCategorieOrder = async (categories) => {
    const db = getFirestore();

    try {


        for (let cat of categories) {
            await updateDoc(doc(db, '/categories', cat.id), cat)
        }

        M.toast({ html: "Información actualizada correctamente", classes: "green darken-4" });
        return true;
    } catch (error) {

        M.toast({ html: "Ocurrio un error al actualizar la información." });
        return false;
    }
}

export const deleteCategorie = async (idCategorie) => {
    const db = getFirestore();

    try {
        await deleteDoc(doc(db, '/categories', idCategorie))
        M.toast({ html: "Información eliminada correctamente", classes: "green darken-4" });
        return true;
    } catch (error) {

        M.toast({ html: "Ocurrio un error al eliminar la información." });
        return false;
    }
}

export const getCategorie = async (idCategorie) => {
    const db = getFirestore();

    try {
        const snap = await getDoc(doc(db, '/categories', idCategorie));

        if (snap.exists()) {
            return snap;
        } else {
            M.toast({ html: "La información ya no se encuentra o no existe." });
            return false;
        }
    } catch (error) {

        return false;
    }
}


export const getAllCategories = async () => {
    const db = getFirestore();

    try {
        const docs = await getDocs(collection(db, `/categories`));
        if (docs.empty) {
            return [];
        } else {
            let arr = [];
            for (const doc of docs.docs) {
                let data = doc.data();
                data.id = doc.id;
                arr.push(data);
            }
            return arr;
        }

    } catch (error) {

        return [];
    }
}

//Products

export const addProduct = async (dataProduct) => {
    const db = getFirestore();

    try {

        if (typeof dataProduct.img === 'object') {
            dataProduct.img = await uploadImage(dataProduct.img);
        }

        await addDoc(collection(db, `/products`), dataProduct);
        M.toast({ html: "Información almacenada correctamente", classes: "green darken-4" });
        return true;
    } catch (error) {

        M.toast({ html: "Ocurrio un error al guardar la información." });
        return false;
    }
}

export const updateProduct = async (dataProduct, idProduct) => {
    const db = getFirestore();

    try {

        if (typeof dataProduct.img === 'object') {
            dataProduct.img = await uploadImage(dataProduct.img);
        }

        await updateDoc(doc(db, '/products', idProduct), dataProduct)
        M.toast({ html: "Información actualizada correctamente", classes: "green darken-4" });
        return true;
    } catch (error) {
        console.log(error);
        M.toast({ html: "Ocurrio un error al actualizar la información." });
        return false;
    }
}

export const deleteProduct = async (idProduct) => {
    const db = getFirestore();

    try {
        await deleteDoc(doc(db, '/products', idProduct))
        M.toast({ html: "Información eliminada correctamente", classes: "green darken-4" });
        return true;
    } catch (error) {

        M.toast({ html: "Ocurrio un error al eliminar la información." });
        return false;
    }
}

export const getProduct = async (idProduct) => {
    const db = getFirestore();

    try {
        const snap = await getDoc(doc(db, '/products', idProduct));

        if (snap.exists()) {
            return snap;
        } else {
            M.toast({ html: "El producto no se encuentra o no existe." });
            return false;
        }
    } catch (error) {

        return false;
    }
}




export const getAllProducts = async () => {
    const db = getFirestore();

    try {
        const docs = await getDocs(collection(db, `/products`));
        if (docs.empty) {
            return [];
        } else {
            let arr = [];
            for (const doc of docs.docs) {
                let data = doc.data();
                data.id = doc.id;
                arr.push(data);
            }
            return arr;
        }

    } catch (error) {

        return [];
    }
}

export const getAllProductsPos = async () => {
    const db = getFirestore();

    try {
        const docs = await getDocs(query(collection(db, `/products`), where('active', '==', true)));
        if (docs.empty) {
            return [];
        } else {
            let arr = [];
            for (const doc of docs.docs) {
                let data = doc.data();
                data.id = doc.id;
                arr.push(data);
            }
            return arr;
        }

    } catch (error) {

        return [];
    }
}


//Subir imagen
export async function uploadImage(file) {
    const storage = getStorage();

    // Nombre del archivo en el storage (puedes modificar esto según tus necesidades)
    const fileName = `${new Date().getTime()}_${file.name}`;

    // Referencia al archivo dentro del storage
    const storageRef = ref(storage, `products/${fileName}`);

    // Subir archivo al storage de Firebase
    try {
        await uploadBytes(storageRef, file);

        return fileName;
    } catch (error) {
        return false;
    }
}

export async function uploadImageGen(file, path) {
    const storage = getStorage();

    // Nombre del archivo en el storage (puedes modificar esto según tus necesidades)
    const fileName = `${new Date().getTime()}_${file.name}`;

    // Referencia al archivo dentro del storage
    const storageRef = ref(storage, `${path}/${fileName}`);

    // Subir archivo al storage de Firebase
    try {
        await uploadBytes(storageRef, file);

        return fileName;
    } catch (error) {
        return false;
    }
}

const getImageExtension = (fileName) => {
    // Obtener la extensión después del último punto en el nombre del archivo
    return fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
}

//Subir imagen
export async function uploadLogo(file) {
    const storage = getStorage();

    // Nombre del archivo en el storage (puedes modificar esto según tus necesidades)
    const fileName = `logo_img.${getImageExtension(file.name)}`;

    // Referencia al archivo dentro del storage
    const storageRef = ref(storage, `logo/${fileName}`);

    // Subir archivo al storage de Firebase
    try {
        await uploadBytes(storageRef, file);

        return fileName;
    } catch (error) {
        return false;
    }
}

//Descargar imagen
export async function getImageUrl(img) {

    const storage = getStorage();

    // Referencia al archivo dentro del storage
    const storageRef = ref(storage, `products/${img}`);

    // Subir archivo al storage de Firebase
    try {
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        return false;
    }
}

export async function getImageUrlPath(img,path) {

    const storage = getStorage();

    // Referencia al archivo dentro del storage
    const storageRef = ref(storage, `${path}/${img}`);

    // Subir archivo al storage de Firebase
    try {
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        return false;
    }
}

export async function getImageLogo(img) {

    const storage = getStorage();

    // Referencia al archivo dentro del storage
    const storageRef = ref(storage, `logo/${img}`);

    // Subir archivo al storage de Firebase
    try {
        const url = await getDownloadURL(storageRef);
        return url;
    } catch (error) {
        return false;
    }
}


export const getAllSettings = async () => {
    const db = getFirestore();

    try {
        const docs = await getDocs(collection(db, `/settings`));
        if (docs.empty) {
            return {};
        } else {
            let data = docs.docs[0].data();
            data.id = docs.docs[0].id;
            return data;
        }
    } catch (error) {

        return {};
    }
}


export const updateSettings = async (settings) => {

    const db = getFirestore();
    try {

        let docRef;

        let docId = false;


        if (typeof settings.img === 'object') {
            settings.img = await uploadLogo(settings.img);
            delete settings.imgPre;
        }

        if (settings.id) {
            docRef = doc(db, 'settings', settings.id);
            await setDoc(docRef, settings);

            docId = settings.id;
        } else {
            docRef = await addDoc(collection(db, 'settings'), settings);

            docId = docRef.id;
        }

        M.toast({ html: "Información almacenada correctamente", classes: "green darken-4" });
        return docId;
    } catch (error) {

        M.toast({ html: "Ocurrio un error al guardar la información." });
    }
}


//Inventario
export const actualizarInventarioConHistorial = async (productId, cantidad, tipoAccion) => {

    try {


        const db = getFirestore();
        const productoRef = doc(db, '/products', productId);

        // Obtener la fecha actual
        const fechaActual = Timestamp.now();

        // Actualizar inventario del producto
        const productoSnapshot = await getDoc(productoRef);

        if (productoSnapshot.data().withInventory) {

            const inventarioActual = productoSnapshot.data().inventory || 0;
            const nuevoInventario = tipoAccion === 'agregado' ? inventarioActual + cantidad : inventarioActual - cantidad;

            await setDoc(productoRef, { inventory: nuevoInventario }, { merge: true });

            // Guardar en la subcolección historial_inventario
            const historialRef = collection(productoRef, 'historic_inventory');
            await addDoc(historialRef, {
                date: fechaActual,
                quantity: cantidad,
                type_action: tipoAccion,
                inventario_before: inventarioActual,
                inventario_after: nuevoInventario
            });

            M.toast({ html: "Información almacenada correctamente", classes: "green darken-4" });

            return true;
        }
        return false;
    } catch (error) {

        return false;
    }
}


export const updateInventoryNoHistoric = async (productId, cantidad, tipoAccion) => {

    try {
        const db = getFirestore();
        const productoRef = doc(db, '/products', productId);

        // Actualizar inventario del producto
        const productoSnapshot = await getDoc(productoRef);
        if (productoSnapshot.data().withInventory) {

            const inventarioActual = productoSnapshot.data().inventory || 0;
            const nuevoInventario = tipoAccion === 'agregado' ? inventarioActual + cantidad : inventarioActual - cantidad;

            await setDoc(productoRef, { inventory: nuevoInventario }, { merge: true });

            return true;
        }
        return false;
    } catch (error) {

        return false;
    }
}


export const obtenerHistorialProducto = async (productId) => {
    try {
        const db = getFirestore();
        // Referencia al documento del producto específico
        const productoRef = doc(db, 'products', productId);

        // Referencia a la subcolección "historic_inventory"
        const historialRef = collection(productoRef, 'historic_inventory');

        // Obtener los documentos de la subcolección
        const querySnapshot = await getDocs(historialRef);

        // Extraer los datos de los documentos
        const historial = [];
        querySnapshot.forEach((doc) => {
            historial.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return historial;

    } catch (error) {
        return [];
    }
}

export const restInventoryTemporal = async (order) => {
    // Creamos una copia profunda del objeto order para evitar mutaciones de objetos inmutables
    const newOrder = { ...order, items: order.items.map(item => ({ ...item })) };

    for (let item of newOrder.items) {
        // Checamos si tiene inventario y no está marcado como check
        if (item.inventory && !item.check && await updateInventoryNoHistoric(item.id, 1, "vendido")) {
            // Al ser una copia, podemos asignar el valor sin errores
            item.check = true;
        }
    }

    return newOrder;
}


export const filterInventory = async (productId, startOfDay, endOfDay) => {
    try {
        const db = getFirestore();


        // Convertir las fechas a Timestamps de Firebase
        const startTimestamp = Timestamp.fromDate(startOfDay);
        const endTimestamp = Timestamp.fromDate(endOfDay);

        // Consultar Firestore para filtrar por el campo 'date' en el rango
        const q = query(
            collection(db, `/products/${productId}/historic_inventory`),
            where('date', '>=', startTimestamp),
            where('date', '<=', endTimestamp)
        );

        const querySnapshot = await getDocs(q);

        const filteredData = [];
        querySnapshot.forEach((doc) => {
            filteredData.push(doc.data());
        });

        return filteredData;

    } catch (error) {
        console.error("Error al obtener los documentos: ", error);
    }
}


//Bills
export const addBill = async (dataProduct) => {
    const db = getFirestore();

    try {

        if (typeof dataProduct.img === 'object') {
            dataProduct.img = await uploadImageGen(dataProduct.img, 'bills');
        }

        dataProduct.date = Timestamp.now();

        await addDoc(collection(db, `/bills`), dataProduct);
        M.toast({ html: "Información almacenada correctamente", classes: "green darken-4" });
        return true;
    } catch (error) {

        M.toast({ html: "Ocurrio un error al guardar la información." });
        return false;
    }
}


export const getMonthDateRange = (year, month) => {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0, 23, 59, 59, 999);

    const start = Timestamp.fromDate(startDate);
    const end = Timestamp.fromDate(endDate);

    return { start, end };
};

export const fetchBills = async (month, year) => {
    const db = getFirestore();
    const { start, end } = getMonthDateRange(year, month);
    const billsRef = collection(db, "bills");

    // Creamos la consulta con el rango de fechas
    const q = query(billsRef, where("date", ">=", start), where("date", "<=", end));

    try {
        const querySnapshot = await getDocs(q);
        let results = [];
        for(let doc of querySnapshot.docs){
            let urlImg = '';
            if(doc.data().img){
                if (doc.data().img !== "") {
                    urlImg = await getImageUrlPath(doc.data().img,'bills');
                    console.log(urlImg);
                }
            }

            results.push({ id: doc.id, imgPre: urlImg, ...doc.data() })
        }

 
        return results;
    } catch (error) {
        console.error("Error al obtener las facturas:", error);
        return [];
    }
};