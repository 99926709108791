import { parse } from 'date-fns';
import M from 'materialize-css';


export const CENTER_INIT_MAP = [19.8172427, -97.3815796];


export const MENU_ADMIN = [

  {
    title: "PRODUCTOS",
    link: '/products',
    icon: 'view_comfy'
  },
  {
    title: "CATEGORÍAS",
    link: '/categories',
    icon: 'list'
  },
  {
    title: "INVENTARIO",
    link: '/inventory',
    icon: 'assignment_turned_in'
  },
  {
    title: "PUNTO DE VENTA",
    link: '/pos',
    icon: 'shopping_basket'
  },
  {
    title: "PUNTO DE VENTA RESTAURANTE",
    link: '/posRest',
    icon: 'restaurant_menu'
  },
  {
    title: "VENTAS",
    link: '/sales',
    icon: 'attach_money'
  },
  {
    title: "ORDENES",
    link: '/orders',
    icon: 'assignment'
  },
  {
    title: "ENTREGAS",
    link: '/toDeliver',
    icon: 'airport_shuttle'
  },
  {
    title: "FACTURAS",
    link: '/bills',
    icon: 'credit_card'
  },
  {
    title: "REPORTES",
    link: '/charts',
    icon: 'insert_chart'
  },
  {
    title: "CONFIGURACIONES",
    link: '/settings',
    icon: 'settings'
  },
 
  



]

export function initFloatButton() {
  M.FloatingActionButton.init(document.querySelectorAll('.fixed-action-btn'), {});
}

export function formatDate(date) {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
  const year = d.getFullYear();
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function parseDate(dateString) {
  if (typeof dateString === 'string') {
    // Define el formato en el que estás ingresando la fecha
    const dateFormat = 'yyyy-MM-dd';
    // Parsear la fecha
    const parsedDate = parse(dateString, dateFormat, new Date());

    return parsedDate;
  }

  return false;

}

export function setEndOfDay(date) {

  if (typeof date === 'object') {
    const endDate = new Date(date);
    endDate.setHours(23, 59, 59, 999);
    return endDate;
  }

  return false;

}

export function parseMoney(x) {

  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function getMonthStartEnd(month, year) {
  // El mes en JavaScript es 0-indexado, así que restamos 1.
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0, 23, 59, 59, 999);

  return {
    start: startDate,
    end: endDate
  };
}

export const PUBLIC_PATHS = [
  '/',
  '/aviso-de-privacidad'
]


function haversineDistance(coords1, coords2) {
  const [lat1, lon1] = coords1;
  const [lat2, lon2] = coords2;

  const R = 6371; // Radio de la Tierra en km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distancia en km
  return distance;
}

export const groupLocationsByProximity = (locations, threshold = 5) => {
  const groups = [];

  locations.forEach((location) => {
    let addedToGroup = false;

    for (let group of groups) {
      // Compara la distancia entre la nueva ubicación y el centro del grupo
      if (haversineDistance(group[0], location) <= threshold) {
        group.push(location);
        addedToGroup = true;
        break;
      }
    }

    // Si no se ha añadido a ningún grupo, crea uno nuevo
    if (!addedToGroup) {
      groups.push([location]);
    }
  });

  return groups;
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}


export const formatTime = (timeString) => {

  if (timeString) {
    const time = timeString.split(':');

    if (time.length === 2) {
      // Asegurarse de que las horas y minutos tengan dos dígitos
      let hours = time[0].padStart(2, '0');
      let minutes = time[1].padStart(2, '0');

      return `${hours}:${minutes}`;
    }
    return null;
  }

}

export const isOpenNow = (schedule) => {

  if(!schedule){
    return false;
  }
  // Obtenemos el día actual (0 es domingo, 1 es lunes, etc.)
  let today = new Date().getDay();

  // Ajustamos para que domingo sea 7 en lugar de 0
  today = today === 0 ? 7 : today;

  // Buscamos el día actual en el objeto `schedule`
  const dayKeys = Object.keys(schedule);
  const todaySchedule = dayKeys.find(day => schedule[day].order === today);

  if (!todaySchedule) {
    return false; // Si no hay un horario para el día actual
  }

  const { closed, open, close } = schedule[todaySchedule];

  if (closed) {
    return false; // Está cerrado
  }

  // Si no está cerrado, verificamos la hora actual
  const now = new Date();
  const nowTime = now.toTimeString().split(" ")[0]; // Obtenemos la hora en formato HH:MM

  // Comparamos las horas (si `open` y `close` no están vacíos)
  if (open && close && nowTime >= open && nowTime <= close) {
    return true; // Está abierto dentro del horario
  }

  return false; // Fuera del horario o sin datos de apertura/cierre
};

export const divideArray = (arreglo, tamañoSubarreglo) => {
  const resultado = [];

  for (let i = 0; i < arreglo.length; i += tamañoSubarreglo) {
      resultado.push(arreglo.slice(i, i + tamañoSubarreglo));
  }

  return resultado;
}


export const  moverElemento = (arr, indexViejo, indexNuevo) => {
  if (indexViejo < 0 || indexViejo >= arr.length || indexNuevo < 0 || indexNuevo >= arr.length) {
      return arr; // Asegura que los índices estén dentro de los límites del arreglo
  }
  
  const elemento = arr.splice(indexViejo, 1)[0]; // Elimina el elemento del índice viejo
  arr.splice(indexNuevo, 0, elemento); // Inserta el elemento en el índice nuevo
  return arr;
}

export  const isValueAllowed = (values) => {
  const { floatValue } = values;
  return floatValue === undefined || (floatValue >= 0 && floatValue <= 999999999.99);
};

export const formatTimestamp = (timestamp) => {
  if (!timestamp) return "";

  const date = timestamp.toDate();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};