
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createRoutesFromElements, createBrowserRouter, Route, Outlet, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Dashboard from '../admin_components/dashboard';
import Products from "../admin_components/products";
import Categories from "../admin_components/categories";
import CategorieForm from "../admin_components/categorieForm";
import ProductForm from "../admin_components/productForm";
import Pos from "../pos_components/pos";
import Sales from "../admin_components/sales";
import Login from "../login";
import Reports from "../admin_components/reports";
import PosOnline from "../orders_online/pos_online";
import * as utils from '../utils';
import Orders from "../admin_components/orders";
import PostRestaurant from "../pos_components/posRest";
import Command from "../pos_components/command";
import useProducts from "../hooks/useProducts";
import useCategories from "../hooks/useCategories";
import useSettings from "../hooks/useSettings";
import ToDeliver from "../admin_components/ToDeliver";
import Configs from "../admin_components/configs";
import Inventory from "../admin_components/inventory";
import InventoryHistoric from "../admin_components/inventoryHistoric";
import AvisoPrivacidad from "../commons_components/avisoPrivacidad";
import PageNotFound from "../commons_components/404";
import Bills from "../admin_components/bills";

function SessionProvider() {

  const appData = useSelector((state) => state.appData);

  useCategories();
  useProducts();
  useSettings();


  const [user, setUser] = useState(false);
  const location = useLocation();

  useEffect(() => {
    observerAuth();
  }, []);


  async function observerAuth() {

    const auth = getAuth();

    onAuthStateChanged(auth, (usr) => {

      if (usr) {
        setUser(usr);
      } else {
        setUser(false);
      }
    }, (error) => {

    }, (completed) => {

    })
  }


  if(user && !appData.settings){
    return <Configs/>;
  }

  if (user || utils.PUBLIC_PATHS.indexOf(location.pathname) !== -1) {
    return <Outlet />;
  } else {
    return <Login setUser={setUser} />
  }

};


export const router = createBrowserRouter(
  createRoutesFromElements([
    <Route element={<SessionProvider />} >
      <Route>
        <Route path="/" element={<PosOnline />} />
        <Route path="/bills" element={<Bills />} />
        <Route path="/admin" element={<Dashboard />} />
        <Route path="/pos" element={<Pos />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/inventory/:id" element={<InventoryHistoric />} />
        <Route path="/posRest" element={<PostRestaurant />} />
        <Route path="/charts" element={<Reports />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/categorie/:id" element={<CategorieForm />} />
        <Route path="/addCategorie" element={<CategorieForm />} />
        <Route path="/products" element={<Products />} />
        <Route path="/addProduct" element={<ProductForm />} />
        <Route path="/product/:id" element={<ProductForm />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/toDeliver" element={<ToDeliver />} />
        <Route path="/addCommand/:table" element={<Command />} />
        <Route path="/editCommand/:idCommand" element={<Command />} />
        <Route path="/editCommand_fo/:idCommand" element={<Command />} />
        <Route path="/editOrder/:idCommand" element={<Command />} />
        <Route path="/settings" element={<Configs/>} />
        <Route path="/aviso-de-privacidad" element={<AvisoPrivacidad/>} />
        <Route path="*" element={<PageNotFound />}/>
      </Route>
    </Route>
  ]))


