import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SalesChart = ({ orders }) => {
  // Procesar los datos
  const productSales = {};

  orders.forEach((order) => {
    order.items.forEach((item) => {
      if (productSales[item.name]) {
        productSales[item.name] += item.quantity || 1;
      } else {
        productSales[item.name] = item.quantity || 1;
      }
    });
  });

  // Crear etiquetas y datos para el gráfico
  const labels = Object.keys(productSales);
  const dataValues = Object.values(productSales);

  const data = {
    labels: labels, // Nombres de los productos
    datasets: [
      {
        label: 'Productos Vendidos',
        data: dataValues, // Cantidades vendidas
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ventas de Productos',
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default SalesChart;
