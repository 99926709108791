
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux';
import noImage from '../assets/noImage.jpg';
import * as utils from '../utils';
import LazyLoad from 'react-lazy-load';

export default function Inventory() {

    const appData = useSelector((state) => state.appData);
    const navigate = useNavigate();

    return (
        <div className="container">
            <div className="row">
                <div className="col s1 left-align">
                    <Link to='/admin' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className="col s11 left-align">
                    <h4>Inventario</h4>
                </div>
                <div className="col s12">
                    <div className="divider"></div>
                </div>
            </div>

            <div className='row'>
                <div className='col s12 left-align'>
                    <h5 className='boldText'>Catégorias</h5>
                </div>
                {
                    appData.categories.map((c) => {
                        return <div className='col s4 m3 l2' key={c.id} style={{ marginTop: '1rem' }}>
                            <a href={`#${c.id}`} style={{ textDecoration: 'none', fontSize: '20px' }} className='black-text'>{c.name}</a>
                        </div>
                    })
                }
            </div>
            <div className='row'>
                <div className='col s12'>
                    {
                        appData.categories.map((v) => {

                            return (
                                <div key={v.id} id={v.id} className="section scrollspy">
                                    <h4 className="boldText">{v.name}</h4>
                                    <div className='divider' />
                                    <div className='row' >
                                        {
                                            utils.divideArray(appData.products.filter((prod) => prod.categorie === v.id && prod.withInventory), 4).map((prods, i) => {
                                                return <div className='row' key={i}>
                                                    {prods.map((p) => (

                                                        <div className='col s12 m3 waves-effect' 
                                                        style={{ marginTop: '1rem', borderRight: '1px solid' }} key={p.id + '_' + i}
                                                        onClick={()=>{
                                                            navigate(`/inventory/${p.id}`)
                                                        }}
                                                        >
                                                            <div className='row' >
                                                                <div className='col s12 center-align'>
                                                                    <LazyLoad once>
                                                                        <img src={p.img ? p.img : noImage} className='responsive-img' style={{ maxHeight: '200px', borderRadius:'10px' }} alt={p.name} />
                                                                    </LazyLoad>
                                                                </div>
                                                                <div className='col s12 center-align'>
                                                                    <p className='boldText'>{p.name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
