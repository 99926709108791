import { useEffect, useState } from "react";
import * as loginUtils from './firebase_utils/login';
import M from 'materialize-css';


export default function Login({ setUser }) {

    const [email, setEmail] = useState('');
    const [forgotEmail, setForgotEmail] = useState('');
    const [pass, setPass] = useState('');
    const [loading, setLoading] = useState(false);

    async function loginAuth() {
        try {
            setLoading(true);
            setUser(await loginUtils.loginFunction(email, pass));
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    async function resetPass(){
        setLoading(true);
        if(await loginUtils.resetPass(forgotEmail)){
            M.Modal.getInstance(document.getElementById('modalForgot')).close()
        }
        setLoading(false);
    }

    useEffect(() => {
        M.Modal.init(document.querySelectorAll('.modal'), {
            dismissible: false,
            onCloseEnd: () => {
                setForgotEmail('');
            }
        });
    }, [])

    return (
        <div className="cotainer">
            <div className="row">
                <div className="col s12">
                    <div className="valign-wrapper" style={{ height: '500px', width: '100%' }}>

                        <div className="container">
                            <div className="row">
                                <div className="col s12 center-align">
                                    <h5>Iniciar sesión</h5>
                                </div>
                                <div className="input-field col s12">
                                    <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <label htmlFor="email">Correo:</label>
                                </div>
                                <div className="input-field col s12">
                                    <input type="password" id="pass" value={pass} onChange={(e) => setPass(e.target.value)} />
                                    <label htmlFor="pass">Contraseña:</label>
                                </div>
                                <div className="col s12 left-align teal-text accent-4">
                                    <span className="cursor_pointer"
                                    onClick={()=>{
                                        M.Modal.getInstance(document.getElementById('modalForgot')).open();
                                    }}
                                    >Olvide mi contraseña</span>
                                </div>
                                <div className="col s12 center-align">
                                    <button className="btn green"
                                        disabled={loading}
                                        onClick={() => {
                                            loginAuth();
                                        }}
                                    >Ingresar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="modalForgot" className="modal">
                <div className="modal-content">
                    <div className='row'>
                        <div className="col s12 right-align">
                            <i className="material-icons cursor_pointer"
                              onClick={()=>{
                                M.Modal.getInstance(document.getElementById('modalForgot')).close();
                            }}
                            >close</i>
                        </div>
                        <div className="col s12 left-align">
                            *Ingresa tu correo electrónico para restablecer tu contraseña.
                        </div>
                        <div className='input-field col s12'>
                                <input type="text" id="forgotEmail" value={forgotEmail}
                                onChange={(e)=>setForgotEmail(e.target.value)}/>
                                <label htmlFor="forgotEmail">Correo:</label>
                        </div>
                        <div className="col s12 center-align">
                            <button className="btn waves-effect green"
                            disabled={forgotEmail.trim()==="" || loading}
                            onClick={()=>{
                                resetPass();
                            }}
                            >Enviar</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    )

}