import {
    getAuth,
    signInWithEmailAndPassword, signOut, sendPasswordResetEmail
} from "firebase/auth";
import M from 'materialize-css';



export const loginFunction = async (email, password) => {

    try {

        const auth = getAuth();
        let response = await signInWithEmailAndPassword(auth, email, password);
        if (response.user) {
            return response.user;
        }

    } catch (error) {

        if (error && error.code) {
            if (error.code === 'auth/invalid-email') {
                errortToast("Correo electrónico no válido.");
            } else if (error.code === 'auth/invalid-password') {
                errortToast("Contraseña incorrecta.");
            } else if (error.code === 'auth/user-not-found') {
                errortToast("Usuario no encontrado.");
            } else {
                errortToast("Ocurrio un error al ingresar intente nuevamente.");
            }
        } else {
            errortToast("Ocurrio un error al ingresar intente nuevamente.");
        }

        return false;
    }

}

export const logoutFunction = async () => {
    try {
        const auth = getAuth();
        await signOut(auth);

        return true;
    } catch (err) {
        errortToast("No se logro terminar la sesión");
        return false;
    }
}

export const resetPass = async (email) => {
    try {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, email);
        successToast('Revisa tu correo electrónico para restablecer tu contraseña');
        return true;
    } catch (error) {
        if (error && error.code) {
            if (error.code === 'auth/invalid-email') {
                errortToast("Correo electrónico no válido.");
            } else if (error.code === 'auth/invalid-password') {
                errortToast("Contraseña incorrecta.");
            } else if (error.code === 'auth/user-not-found') {
                errortToast("Usuario no encontrado.");
            } else {
                errortToast("Ocurrio un error al ingresar intente nuevamente.");
            }
        } else {
            errortToast("Ocurrio un error al ingresar intente nuevamente.");
        }
        return false;
    }
}

export const errortToast = (message) => {
    M.toast({ html: `<i class="material-icons left">warning</i>${message}`, classes: 'red darken-1' });
}


export const successToast = (message) => {
    M.toast({ html: `<i class="material-icons left">check_circle</i>${message}`, classes: 'green darken-1' });
}