import { useEffect } from 'react';
import M from 'materialize-css';

export default function ModalAlert({dataAlert, setDataAlert}) {


    useEffect(() => {
        M.Modal.init(document.getElementById('modalAlert'), {
            dismissible: false,
            onCloseStart: () => {
                setDataAlert(false);
            }
        });
    }, [])

    useEffect(() => {
        if (dataAlert) {
            M.Modal.getInstance(document.getElementById('modalAlert')).open();
        } else {
            M.Modal.getInstance(document.getElementById('modalAlert')).close();
        }
    }, [dataAlert])

    return (
        <div id="modalAlert" className="modal">
            <div className="modal-content">
                <h5>{dataAlert ? dataAlert.message : ''}</h5>
                <p>{dataAlert ? dataAlert.text : ''}</p>
            </div>
            <div className="modal-footer">
                {
                    dataAlert && dataAlert.actionConfirm ?
                        <button  className="waves-effect waves-green btn-flat"
                            onClick={(e) => {
                                e.preventDefault();
                                dataAlert.actionConfirm();

                                setTimeout(()=>{
                                    M.Modal.getInstance(document.getElementById('modalAlert')).close();
                                },100);
                            }}
                        >Sí</button>
                        : ''
                }

                {
                    dataAlert && dataAlert.confirmA ?
                        <button  className="modal-close waves-effect waves-green btn-flat"
                            onClick={(e) => {
                                e.preventDefault();
                                dataAlert.confirmA(true);
                            }}
                        >Sí</button>
                        : ''
                }



                <button className="modal-close waves-effect waves-green btn-flat">No</button>
            </div>
        </div>
    )
}