import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from "react-router-dom"
import noImage from '../assets/noImage.jpg';
import M from 'materialize-css';
import * as utils from '../utils';
import ModalAlert from '../commons_components/modalAlert';
import * as posUtils from '../firebase_utils/pos';
import { useSelector } from 'react-redux';
import LazyLoad from 'react-lazy-load';
import * as fb_utils from '../firebase_utils';

export default function Command() {

    const appData = useSelector((state) => state.appData)

    const params = useParams();
    const locationRoute = useLocation();
    const [order, setOrder] = useState([]);
    const [table, setTable] = useState('');
    const [dataAlert, setDataAlert] = useState(false);
    const [orderId, setOrderID] = useState(false);
    const [loading, setLoading] = useState(false);
    const [esOrderOnline, setOrderOnline] = useState(false);
    const [toDelete, setToDelete] = useState([]);

    useEffect(() => {

        if (params && params.idCommand && (locationRoute.pathname.includes('/editCommand/') || locationRoute.pathname.includes('/editCommand_fo/'))) {
            getCommand(params.idCommand);
        }

        if (params && params.idCommand && locationRoute.pathname.includes('/editOrder/')) {
            getOrderOnline(params.idCommand);
        }

        if (params && params.table && locationRoute.pathname.includes('/addCommand/')) {
            setTable(params.table);
        }

        M.Modal.init(document.querySelectorAll('.modal'), {
            onCloseEnd: () => {
            }
        });

        setTimeout(() => {
            M.updateTextFields();
        }, 100)
    }, [locationRoute, params]);

    const getCommand = async (idCommand) => {
        setLoading(true);
        let commandE = await posUtils.getCommand(idCommand);

        if (commandE) {
            let d = commandE.data();

            setTable(d.table);
            setOrder(d.items);
            setOrderID(idCommand);
            setTimeout(() => {
                M.updateTextFields();
            }, 100)
        }

        setLoading(false);
    }

    const getOrderOnline = async (idCommand) => {
        setLoading(true);
        let commandE = await posUtils.getOrderOnline(idCommand);

        if (commandE) {
            let d = commandE.data();
        
            setOrderOnline(true);
            setTable(d.contactOrder.name);
            setOrder(d.items);
            setOrderID(idCommand);
            setTimeout(() => {
                M.updateTextFields();
            }, 100)
        }

        setLoading(false);
    }




    // const divideArray = (arreglo, tamañoSubarreglo) => {
    //     const resultado = [];

    //     for (let i = 0; i < arreglo.length; i += tamañoSubarreglo) {
    //         resultado.push(arreglo.slice(i, i + tamañoSubarreglo));
    //     }

    //     return resultado;
    // }

    const addProduct = (nP) => {

        order.push(nP);

        M.Toast.dismissAll();

        M.toast({ html: `Producto Agregado <i class="material-icons right">check_circle</i>`, classes: 'green darken-3' })

        setOrder([...order]);
    }


    const deleteProduct = (p, i) => {

        setDataAlert({
            message: `¿Está seguro de eliminar este producto de la orden?`,
            actionConfirm: () => {

                toDelete.push(order[i]);

                setToDelete([...toDelete]);

                const newOrder = [
                    ...order.slice(0, i),
                    ...order.slice(i + 1)
                ];

                try {
                    if (!newOrder.length) {
                        M.Modal.getInstance(document.getElementById('modalOrder')).close();
                    }
                } catch (error) {

                }
                setOrder(newOrder);
            }
        })

    }

    const updateAddcommand = async () => {
        setLoading(true);

        if (table.trim() !== '') {
            if (!orderId) {
                let newOrder = await posUtils.addComand({
                    table,
                    items: order
                });

                if (newOrder.id) {
                    setOrder(newOrder.items);
                    setOrderID(newOrder.id);
                }
            } else {
                let newOrder = await posUtils.updateCommand({
                    table,
                    items: order
                }, orderId);

                setOrder(newOrder.items);
            }
        } else {
            M.toast({ html: "<i class='material-icons left'>warning</i> Faltan datos para generar la orden", classes: 'red darken-4' });
        }

        await deleteProducts();

        setLoading(false);

    }

    const deleteProducts = async () => {
        if (toDelete.length) {
            for (let p of toDelete) {
                await fb_utils.updateInventoryNoHistoric(p.id, 1, 'agregado');
            }
            setToDelete([]);
        }
    }



    const updateOrderOnline = async () => {
        setLoading(true);

        if (order.length) {

            let newOrder = await posUtils.updateOrderOnline({
                items: order
            }, orderId);

            await deleteProducts();

            setOrder(newOrder.items);

        } else {
            M.toast({ html: "<i class='material-icons left'>warning</i> Faltan datos para generar la orden", classes: 'red darken-4' });
        }

        setLoading(false);

    }


    return (
        <div className="container">
            <div className="row">
                <div className="col s1 left-align">
                    <Link to={esOrderOnline || locationRoute.pathname.includes('/editCommand_fo/') ? '/orders' : '/posRest'} className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className='col s11 left-align'>
                    <h4>{esOrderOnline ? 'Orden' : 'Comanda'}</h4>
                </div>
                <div className='col s12'>
                    <div className='divider' />
                </div>
            </div>
            <div className='row'>
                <div className='input-field col s12 m8 '>
                    <input id='table' type='text' value={table}
                        disabled></input>
                    <label htmlFor='table'>{esOrderOnline ? 'Nombre:' : 'Mesa:'}</label>
                </div>
                <div className='col s12 m4 right-algin'>
                    <div className='row'>
                        <div className='col s12'>
                            <button className='btn-flat waves-effect boldText'
                                disabled={loading}
                                onClick={() => {

                                    if (esOrderOnline) {
                                        updateOrderOnline();
                                    } else {
                                        updateAddcommand();
                                    }

                                }}
                            ><i className='material-icons left'>save</i> Guardar</button>
                        </div>
                        <div className='col s12'>
                            <button className='btn-flat waves-effect boldText'
                                onClick={() => {
                                    let instModal = M.Modal.getInstance(document.getElementById('modalOrder'));

                                    if (instModal && order.length) {
                                        instModal.open();
                                    }
                                }}
                            ><i className='material-icons left'>remove_red_eye</i> Orden</button>
                        </div>
                    </div>

                </div>
                <div className='col s12'>
                    <div className='divider'></div>
                </div>
            </div>

            <div className='row'>
                <div className='col s12 left-align'>
                    <h5 className='boldText'>Catégorias</h5>
                </div>
                {
                    appData.categories.map((c) => {
                        return <div className='col s4 m3 l2' key={c.id} style={{ marginTop: '1rem' }}>
                            <a href={`#${c.id}`} style={{ textDecoration: 'none', fontSize: '20px' }} className='black-text'>{c.name}</a>
                        </div>
                    })
                }
            </div>

            <div className='row'>
                <div className='col s12'>
                    {
                        appData.categories.map((v) => {

                            return (
                                <div key={v.id} id={v.id} className="section scrollspy">
                                    <h4 className="boldText">{v.name}</h4>
                                    <div className='divider' />
                                    <div className='row' >
                                        {
                                            utils.divideArray(appData.products.filter((prod) => prod.categorie === v.id ), 3).map((prods, i) => {
                                                return <div className='row' key={i}>
                                                    {prods.map((p) => (

                                                        <div className='col s12 m4' style={{ marginTop: '1rem', borderRight: '1px solid' }} key={p.id + '_' + i}>
                                                            <div className='row' >
                                                                <div className='col s12 center-align'>
                                                                    <LazyLoad once>
                                                                        <img src={p.img ? p.img : noImage} className='responsive-img' style={{ maxHeight: '200px' }} alt={p.name} />
                                                                    </LazyLoad>
                                                                </div>
                                                                <div className='col s12'>
                                                                    <p className='boldText'>{p.name}</p>
                                                                    <p style={{ textAlign: 'justify' }}>{p.description}</p>

                                                                </div>
                                                            </div>
                                                            <div className='row'>

                                                                <div className='col s12 center-align'>

                                                                    <button className='btn green waves-effect'
                                                                        onClick={() => {
                                                                            addProduct(p);
                                                                        }}
                                                                    >Agregar</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
            <div id="modalOrder" className="modal">
                <div className="modal-content">
                    <div className='row contentProductsOnline' >
                        <div className='col s12 right-align'>
                            <i className='material-icons modal-close'>close</i>
                        </div>
                        <div className='col s12'>
                            <h4>Orden</h4>
                            <div className='divider' />
                        </div>
                        <div className='col s12'>
                            {
                                order.length ?
                                    <table className=''>
                                        <thead>
                                            <tr>
                                                <th>Producto</th>
                                                <th className='center-align'>
                                                    <button className='btn-flat waves-effect boldText'
                                                        disabled={loading}
                                                        onClick={() => {
                                                            if (esOrderOnline) {
                                                                updateOrderOnline();
                                                            } else {
                                                                updateAddcommand();
                                                            }
                                                        }}
                                                    ><i className='material-icons left'>save</i> Guardar</button>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {
                                                order.map((v, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <tr>
                                                                <td>
                                                                    <span className='boldText'>{v.name}</span>
                                                                </td>

                                                                <td>
                                                                    <div className='row'>
                                                                        <div className='input-field col s12'>
                                                                            <input type='text' id={`${v.id}_${i}`}
                                                                                value={v.indications} onChange={(e) => {
                                                                                    setOrder(prevOrder => {
                                                                                        // Crear una copia del array order
                                                                                        const newOrder = prevOrder.map((item, index) => {
                                                                                            // Crear una copia del objeto que necesitas modificar
                                                                                            if (index === i) {
                                                                                                return {
                                                                                                    ...item,
                                                                                                    indications: e.target.value // Modificar la propiedad indications
                                                                                                };
                                                                                            }
                                                                                            return item;
                                                                                        });

                                                                                        return newOrder; // Retornar la nueva copia para actualizar el estado
                                                                                    })
                                                                                }}
                                                                            ></input>
                                                                            <label htmlFor={`${v.id}_${i}`}>Indicaciones:</label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className='btn-flat waves-effect'
                                                                        onClick={() => {
                                                                            deleteProduct(v, i);
                                                                        }}
                                                                    ><i className='material-icons'>delete</i></button>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>

                                    : null
                            }
                        </div>
                        {/* {stepOrderView()}
                        {
                            stepOrder === 1 ?
                                <div className='input-field col s12'>
                                    <input id="note" type='text'
                                        value={indications}
                                        onChange={(e) => {
                                            setIndications(e.target.value);
                                        }}
                                    ></input>
                                    <label htmlFor='note'>Indicaciones:</label>
                                    <span className="helper-text"><i className='material-icons left'>info</i>Puedes indicarnos algo especial a tu orden por ejemplo <span className='boldText'>"No agregar salsa roja"</span></span>
                                </div>
                                : null
                        }

                        <div className='col s12 center-align'>
                            {stepOrderButton()}
                        </div> */}
                    </div>
                </div>
            </div>
            <ModalAlert dataAlert={dataAlert} setDataAlert={setDataAlert} />
        </div>
    )


}