import React from "react";
import { useSelector } from 'react-redux';

const AvisoPrivacidad = () => {
  const appData = useSelector((state) => state.appData);


  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <span className="card-title">Aviso de Privacidad</span>
          <p>
            En <strong>{appData.settings ? appData.settings.name : ''}</strong> estamos comprometidos con la protección de los datos personales de nuestros clientes. Por ello, en cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (o la normativa correspondiente en tu país), informamos lo siguiente:
          </p>
          <h5>Datos Personales Recabados</h5>
          <p>
            Los datos personales que recabaremos de usted son:
            <ul>
              <li>Nombre</li>
              <li>Número de teléfono</li>
              <li>Ubicación</li>
            </ul>
          </p>
          <h5>Finalidad del Tratamiento de Datos</h5>
          <p>
            La información recabada será utilizada con la finalidad de:
            <ul>
              <li>Ponernos en contacto con usted para coordinar la entrega de los productos adquiridos.</li>
            </ul>
          </p>
          <h5>Protección de Datos</h5>
          <p>
            Nos comprometemos a proteger su información mediante medidas de seguridad administrativas, técnicas y físicas para evitar su pérdida, mal uso, acceso no autorizado o alteración.
          </p>
          <h5>Derechos del Titular</h5>
          <p>
            Usted tiene derecho a acceder, rectificar, cancelar u oponerse (derechos ARCO) al tratamiento de sus datos personales, conforme a la legislación vigente. Para ejercer estos derechos, por favor comuníquese con nosotros a través del correo electrónico <strong>{appData.settings ? appData.settings.email : ''}</strong> o al teléfono <strong>{appData.settings ? appData.settings.phone : ''}</strong>.
          </p>
          <h5>Cambios al Aviso de Privacidad</h5>
          <p>
            Nos reservamos el derecho de modificar este Aviso de Privacidad en cualquier momento. Las modificaciones serán publicadas en nuestro sitio web <strong>{appData.settings ? appData.settings.urlWeb : ''}</strong>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AvisoPrivacidad;
