import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import * as utils from '../utils';
import * as fb from '../firebase_utils';
import { useSelector } from "react-redux";

export default  function Products(){

    const appData = useSelector((state) => state.appData)

    useEffect(() => {
        utils.initFloatButton();

    }, []);
  

    return (
        <div className="container">
        <div className="row">
        <div className="col s1 left-align">
                    <Link to='/admin' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
            <div className="col s11 left-align">
                <h4>Productos</h4>
            </div>
            <div className="col s12">
                <div className="divider"></div>
            </div>
        </div>

        <div className="row">
                <div className="col s12">
                    <table>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th className="center-align">Activo</th>
                                <th className="center-align">Acción</th>
                                <th className="center-align">Inventario</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            appData.products.map((v) => { 
                                    return (
                                        <tr key={v.id}>
                                            <td>{v.name}</td>
                                            <td className="center-align">{v.active ? 'SI' : 'NO'}</td>
                                            <td className="center-align">
                                                <Link to={`/product/${v.id}`}
                                                    className="material-icons cursor_pointer green-text">edit</Link>
                                                <i className="material-icons cursor_pointer red-text"
                                                    onClick={async () => {

                                                        if (window.confirm('¿Estás seguro de eliminar este producto?')) {
                                                            await fb.deleteProduct(v.id);
                                                        }
                                                    }}
                                                >delete</i>
                                            </td>
                                            <td className="center-align">

                                                    {
                                                        !v.withInventory ?
                                                        'N/A'
                                                        :   <Link to={`/inventory/${v.id}`}
                                                        className={`btn-flat cursor_pointer ${v.inventory > 0 ? 'green-text': 'red-text'}`}>
                                                            <i className="material-icons left">assignment_turned_in</i>  {v.inventory || 0}
                                                        </Link>
                                                    }

                                                  

                                           
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>


        <div className="fixed-action-btn">
                <Link to="/addProduct" className="btn-floating btn-large red">
                    <i className="large material-icons waves-effect">
                        add
                    </i>
                </Link>
            </div>
    </div>
    )
}