import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import M from 'materialize-css';
import * as fb from '../firebase_utils';


export default function CategorieForm() {

    const [formData, setFormData] = useState({ name: "", active: false, order: 0 });
    const [idCategorie, setIdCategorie] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        M.updateTextFields();
        
        if(params && params.id){
            getCategorie(params.id);
        }
    }, [])

    useEffect(()=>{
        if(formData.name.trim() !== ""){
            M.updateTextFields();
        }
    },[formData])

    const getCategorie = async (idCategorie) =>{
         const snap = await fb.getCategorie(idCategorie);

         if(snap){
            const value  = snap.data();
            setFormData({
                name: value.name,
                active: value.active,
                order: value.order || 0
            });

            setIdCategorie(idCategorie);
         }

    }

    return (
        <div className="container">
            <div className="row">
                <div className="col s1 left-align">
                    <Link to='/categories' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className="col s11 center-align">
                    <h4>Categoría</h4>
                </div>
                <div className="col s12">
                    <div className="divider"></div>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12">
                    <input id="name" type="text" maxLength={150} value={formData.name}
                        onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value })
                        }}
                    />
                    <label htmlFor="name">Nombre:</label>
                </div>
                <div className="col s12">
                    <p>
                        <label>
                            <input type="checkbox" id="activo" checked={formData.active}
                                onChange={(e) => {
                                    setFormData({ ...formData, active: e.target.checked })
                                }}
                            />
                            <span>Mostrar en Menú</span>
                        </label>
                    </p>
                </div>
                <div className="col s12 right-align">
                    <button className="btn waves-effect"
                        disabled={formData.name.trim() === ""}
                        onClick={async () => {
                            if (formData.name.trim() !== "" && !idCategorie) {
                                if( await fb.addCategorie(formData)){
                                    navigate('/categories')
                                }
                            }

                            if(idCategorie){
                                if(await fb.updateCategorie(formData,idCategorie)){
                                    navigate('/categories')
                                }
                            }
                        }}
                    >Guardar</button>
                </div>
            </div>
        </div>
    )
}