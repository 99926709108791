import { useEffect } from 'react';
import { collection, onSnapshot, getFirestore, orderBy , query} from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { setCategories, updateCategorie, addCategorie, deleteCategorie } from '../redux/slices/appSlice';


const useCategories = () => {
  const dispatch = useDispatch();

  useEffect(() => {

    const db = getFirestore();

    const q = query(collection(db, 'categories'), orderBy('order'));

    const unsubscribe = onSnapshot(q, async (snapshot) => {

     
      snapshot.docChanges().forEach(async (change) => {
        let categorie = { id: change.doc.id, ...change.doc.data() };
       
        if (change.type === 'added') {
          dispatch(addCategorie(categorie));
        }
        if (change.type === 'modified') {
          dispatch(updateCategorie(categorie));
        }
        if (change.type === 'removed') {
          dispatch(deleteCategorie(categorie.id));
        }
      });


      const categories = [];

      for (let doc of snapshot.docs) {

        let data = doc.data();
        data.id = doc.id;

        categories.push(data);
      }
      
      dispatch(setCategories(categories));
    });

    return () => unsubscribe(); // Cleanup al desmontar
  }, [dispatch]);
};

export default useCategories;