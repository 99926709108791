import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import M from 'materialize-css';
import * as fb from '../firebase_utils';
import noImage from '../assets/noImage.jpg';
import { NumericFormat } from 'react-number-format';
import * as validations from '../firebase_utils/validations';
import { useSelector } from "react-redux";
import * as utils from '../utils';

export default function ProductForm() {

    const appData = useSelector((state) => state.appData)

    const [formData, setFormData] = useState({ img: false, name: '', price: '', description: '', categorie: false, active: false,withInventory:false, notes:false, complements: [] });
    const [idProduct, setIdProduct] = useState(false);
    const [cbarOrigin, setCbarOrigin] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        M.updateTextFields();

        if (params && params.id) {
            getProduct(params.id);
        }
    }, [])

    useEffect(() => {
        if (formData.name.trim() !== "") {
            M.updateTextFields();
        }
    }, [formData])


    const getProduct = async (idProduct) => {
        const snap = await fb.getProduct(idProduct);

        if (snap) {
            const value = snap.data();

            let urlImg = '';
            if (value.img !== "") {
                urlImg = await fb.getImageUrl(value.img);
            }

            setFormData({
                name: value.name,
                price: value.price,
                description: value.description,
                categorie: value.categorie,
                active: value.active,
                imgPre: urlImg,
                cbar: value.cbar,
                img: value.img,
                withInventory: value.withInventory || false,
                complements: value.complements || [],
                notes: value.notes || false
            })
            setCbarOrigin(value.cbar);


            setIdProduct(idProduct);
        }

    }

    const addComplement = () => {

        let complements = formData.complements || [];

        complements.push({});

        setFormData({ ...formData, complements });
    }

    const deleteComplement = (i)=>{
        let complements = formData.complements;

        complements.splice(i,1);

        setFormData({ ...formData, complements });

    }

    return (
        <div className="container">
            <div className="row">
                <div className="col s1 left-align">
                    <Link to='/products' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className="col s11 center-align">
                    <h4>Producto</h4>
                </div>
                <div className="col s12">
                    <div className="divider"></div>
                </div>
            </div>
            <div className="row">
                <div className="col s12 center-align">

                    <img src={formData.img ?
                        typeof formData.img === 'object' ?
                            URL.createObjectURL(formData.img)
                            : formData.imgPre
                        : noImage
                    }
                        className={`rounded cursorPointer ${formData.img ? 'responsive-img imgProduct' : ''}`} onClick={() => {
                            document.getElementById('imgFile').click()
                        }} />

                </div>
                <div className="col s12 m4">
                    <p>
                        <label>
                            <input type="checkbox" id="activo" checked={formData.active}
                                onChange={(e) => {
                                    setFormData({ ...formData, active: e.target.checked })
                                }}
                            />
                            <span className="black-text">Mostrar en menú</span>
                        </label>
                    </p>
                </div>
                <div className="col s12 m4">
                    <p>
                        <label>
                            <input type="checkbox" id="inventory" checked={formData.withInventory}
                                onChange={(e) => {
                                    setFormData({ ...formData, withInventory: e.target.checked })
                                }}
                            />
                            <span className="black-text">Inventario</span>
                        </label>
                    </p>
                    <p className="ft12 mt-1r grey-text">*Cuando el inventario se encuentre 0 no se podra seleccionar este producto.</p>
                </div>
                <div className="col s12 m4">
                    <p>
                        <label>
                            <input type="checkbox" id="notes" checked={formData.notes}
                                onChange={(e) => {
                                    setFormData({ ...formData, notes: e.target.checked })
                                }}
                            />
                            <span className="black-text">Indicaciones</span>
                        </label>
                    </p>
                    <p className="ft12 mt-1r grey-text">*El usuario es capaz de indicar cómo desea el producto.</p>
                </div>
                <div className="input-field col s12">
                    <input id="name" type="text" maxLength={150} value={formData.name}
                        onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value })
                        }}
                    />
                    <label htmlFor="name">Nombre:</label>
                </div>

                <div className="input-field col s12">
                    <input id="cbar" type="text" maxLength={150} value={formData.cbar}

                        onChange={(e) => {
                            setFormData({ ...formData, cbar: e.target.value })
                        }}
                    />
                    <label htmlFor="cbar">Código de Barras:</label>
                </div>

                <div className=" col s12">
                    <label htmlFor="categorie">Categoría:</label>
                    <select id="categorie" className="browser-default" onChange={(e) => {
                        setFormData({ ...formData, categorie: e.target.value });
                    }}
                        value={formData.categorie}
                    >
                        <option>Selecciona una opción</option>
                        {
                            appData.categories.map((c) => {
                                return <option value={c.id} selected={formData.categorie === c.id}>{c.name}</option>
                            })
                        }
                    </select>

                </div>
                <div className="input-field col s12">
                    <NumericFormat
                        id="price"
                        thousandSeparator={','}
                        decimalSeparator="."
                        value={formData.price}
                        isNumericString
                        decimalScale={2}
                        fixedDecimalScale
                        isAllowed={utils.isValueAllowed}
                        prefix="$"
                        allowNegative={false}
                        onValueChange={(values) => {
                            setFormData({ ...formData, price: values.value })
                        }}
                    />
                    <label htmlFor="price">Precio: </label>
                </div>
                <div className="input-field col s12">
                    <input id="description" type="text" maxLength={400} value={formData.description}
                        onChange={(e) => {
                            setFormData({ ...formData, description: e.target.value })
                        }}
                    />
                    <label htmlFor="description">Descripción:</label>
                </div>
                <div className="col s12">
                    <div className="divider" />
                </div>
                {/* <div className="col s12">
                    <h5>Complementos:</h5>
                </div> */}
                {/* <div className="col s12 center-align">
                    <button className="btn-flat waves-effect"
                        onClick={() => {
                            addComplement();
                        }}
                    >
                        <i className="material-icons left">add</i>Agregar complemento
                    </button>
                </div> */}

                {/* <div className="col s12 " style={{ marginTop: '1rem' }}>

                    {formData.complements ? formData.complements.map((complement, i) => {
                        return (
                            <div className="row">
                                <div className="col s10">
                                    <select key={i} className="browser-default" style={{ marginTop: '1rem' }}

                                        value={complement.id || ''}

                                        onChange={(e) => {
                                            let complements = formData.complements || [];
                                            complements[i] = { id: e.target.value }
                                            setFormData({ ...formData, complements });
                                        }}
                                    >
                                        <option>Selecciona una opción</option>
                                        {
                                            appData.products.map((p, i) =>
                                                <option value={p.id} key={i}>{p.name} + ${p.price}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col s2 center-align">
                                    <button className="btn-flat" style={{ marginTop: '1.5rem' }}
                                    onClick={()=>{
                                        deleteComplement(i);
                                    }}
                                    >
                                        <i className="material-icons red-text">delete</i>
                                    </button>
                                </div>
                            </div>
                        )
                    })
                        : null
                    }
                </div> */}


                <input type="file" hidden id="imgFile"

                    onChange={(e) => {
                        if (e.target.files.length) {
                            setFormData({ ...formData, img: e.target.files[0] })
                        } else {
                            setFormData({ ...formData, img: false });
                        }
                    }}
                />
                {/* <div className="col s12" style={{ marginTop: '1rem' }}>
                    <div className="divider" />
                </div> */}
                <div className="col s12 center-align" style={{ marginTop: '1rem' }}>
                    <button className="btn waves-effect"
                        disabled={formData.name.trim() === "" || formData.price.trim() === ""

                            || !formData.categorie || formData.cbar.trim() === ""
                        }
                        onClick={async () => {


                            if (formData.name.trim() !== "" && !idProduct) {
                                if (!await validations.checkCBar(formData.cbar)) {

                                    if (await fb.addProduct(formData)) {
                                        navigate('/products')
                                    }
                                } else {
                                    M.toast({ html: 'El código de barras ya se encuentra registrado.', classes: 'red darken-4' })
                                }
                            }

                            if (idProduct) {

                                const nextStep = true;

                                const checkBar = await validations.checkCBar(formData.cbar);

                                if (checkBar && checkBar !== cbarOrigin) {
                                    M.toast({ html: 'El código de barras ya se encuentra registrado.', classes: 'red darken-4' })
                                } else {
                                    delete formData.imgPre;

                                    if (await fb.updateProduct(formData, idProduct)) {
                                        navigate('/products')
                                    }
                                }
                            }

                        }}
                    ><i className="material-icons left">save</i> Guardar</button>
                </div>
            </div>
        </div>
    )
}