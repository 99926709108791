
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import * as fb_utils from '../firebase_utils';
import { useEffect, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import noImage from '../assets/noImage.jpg';


export default function InventoryHistoric() {

    const params = useParams();
    const navigate = useNavigate();

    const [product, setProduct] = useState(false);
    const [addInventory, setAddInventory] = useState('');
    const [loading, setLoading] = useState(false);
    const [historic, setHistoric] = useState([]);
    const [startHistoric, setStartHistoric] = useState(new Date());

    useEffect(() => {

        if (!params || !params.id) {
            navigate('/inventory');
        }

        if (params.id) {
            getProduct(params.id);
        }

    }, [])

    useEffect(() => {
        if (product) {
            getHistoric();
        }
    }, [product])

    async function getHistoric() {

        let historic = await fb_utils.obtenerHistorialProducto(product.id);

        setHistoric(historic);
    }

    async function getProduct(id) {

        let p = await fb_utils.getProduct(id);

        if (p) {
            let data = p.data();
            data.id = id;
            try {
                if (data.img) {
                    const url = await fb_utils.getImageUrl(data.img);
                    data.img = url;
                }
            } catch (error) {

            }
            setProduct(data);
        } else {
            navigate('/inventory');
        }
    }

    const sendData = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (parseInt(addInventory) > 0) {
            if (await fb_utils.actualizarInventarioConHistorial(product.id, parseInt(addInventory), 'agregado')) {
                setAddInventory('');
                getProduct(product.id);
            }
        }

        setLoading(false);

    }

    const filterByDate = async () => {

        if (startHistoric && product) {


            // Convertir la fecha seleccionada a un objeto Date en UTC
            const selectedDateUTC = new Date(startHistoric + "T00:00:00Z");

            // Crear el rango de búsqueda en UTC (inicio y fin del día seleccionado)
            const startOfDay = new Date(Date.UTC(selectedDateUTC.getUTCFullYear(), selectedDateUTC.getUTCMonth(), selectedDateUTC.getUTCDate(), 0, 0, 0));
            const endOfDay = new Date(Date.UTC(selectedDateUTC.getUTCFullYear(), selectedDateUTC.getUTCMonth(), selectedDateUTC.getUTCDate(), 23, 59, 59, 999));



            let result = await fb_utils.filterInventory(product.id, startOfDay, endOfDay);

            setHistoric(result);

        }

    };


    return (
        <div className="container">
            <div className="row">
                <div className="col s1 left-align">
                    <Link to='/inventory' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className="col s11 left-align">
                    <h4>Inventario</h4>
                </div>
                <div className="col s12">
                    <div className="divider"></div>
                </div>
            </div>

            {
                product ?

                    <div className='row'>
                        <div className='col s12 left-align boldText'>
                            <h3>{product.name}</h3>
                        </div>
                        <div className='col s12 m4 center-align'>
                            <LazyLoad once>
                                <img src={product.img ? product.img : noImage} className='responsive-img' style={{ borderRadius: '10px', maxHeight:'200px' }} alt={product.name} />
                            </LazyLoad>
                        </div>
                        <div className='col s12 m8 left-align'>
                            <form className='row' onSubmit={sendData}>
                                <div className='col s12 boldText '>
                                    En inventario: {product.inventory || 0}
                                </div>
                                <div className='input-field col s12'>
                                    <input type='text' id="addInventory" value={addInventory}
                                        disabled={loading}
                                        onChange={(e) => {
                                            if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                                                setAddInventory(e.target.value);
                                            }

                                        }} />
                                    <label htmlFor='addInventory'>Agregar unidades:</label>
                                </div>
                                <div className='col s12'>
                                    <button className='waves-effect btn boldText green'
                                        disabled={loading || addInventory.trim() === ''}
                                    >Agregar</button>
                                </div>
                            </form>
                        </div>
                        <div className='col s12'>
                            <div className='divider' />
                        </div>

                    </div>


                    : null
            }


            <div className='row'>

                <div className='col s12 left-align'>
                    <h5>Historico</h5>
                </div>

                <div className="input-field col s12 m3">
                    <input type="date" id="startHistoric" value={startHistoric} onChange={(e) => {
                        console.log(e.target.value);
                        setStartHistoric(e.target.value);
                    }} ></input>
                    <label htmlFor="startHistoric">Fecha Inicio:</label>
                </div>
                <div className='col s12'>
                    <button className='btn-flat waves-effect'
                        onClick={filterByDate}
                    >
                        <i className='material-icons left'>filter_list</i>Filtrar
                    </button>
                </div>
                <div className='col s12' style={{marginTop:'1rem'}}>
                    <div className='divider' />
                </div>

                <div className='col s12'>
                    <table>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th className='center-align'>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                historic.length > 0 && historic.map((h, i) => {
                                    return (
                                        <tr key={h.id}>
                                            <td>
                                                {fb_utils.formatearTimestamp(h.date)}
                                            </td>
                                            <td className={
                                                `${h.type_action === 'agregado' ? 'green-text' : 'red-text'} boldText center-align`
                                            }>
                                                {
                                                    h.type_action !== 'agregado' ? '-' : ''
                                                }
                                                {h.quantity}
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>

                    </table>
                </div>
            </div>



        </div>

    )


}