import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import * as utils from '../utils';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Corrige el problema de los íconos por defecto de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapZones = ({ orders, zones }) => {
  return (
    <MapContainer center={utils.CENTER_INIT_MAP} zoom={14} style={{ height: '500px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {orders.map((order, index) => {
        const position = order.address.location;
        const radius = 100; // Radio en metros, por ejemplo, 5 km alrededor de la ubicación

        return (
          <React.Fragment key={index}>
            {
                zones ? 
                <Circle
                center={position}
                radius={radius} // Puedes ajustar el radio según sea necesario
                color="blue" // Color del círculo
                fillColor="blue"
                fillOpacity={0.2}
              />: null
            }
           {
            !zones ?
            <Marker position={position}>
            <Popup>
              <strong>Pedido por:</strong> {order.contactOrder.name}<br />
              <strong>Productos:</strong> {order.items.length}
            </Popup>
          </Marker>
          :null
           }
           
          </React.Fragment>
        );
      })}
    </MapContainer>
  );
};

export default MapZones;
