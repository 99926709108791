import React, { useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const DoughnutChart = ({ salesData }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    
    if (chartRef.current) {
      chartInstanceRef.current = new Chart(chartRef.current, {
        type: 'doughnut',
        data: salesData,
      });
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [salesData]);

  return (
    
    <canvas ref={chartRef} />
   
  );
};

export default DoughnutChart;
