import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import * as utils from '../utils';
import * as pos from '../firebase_utils/pos';
import DoughnutChart from "./doughnutChart";
import LineChart from "./lineChart";
import { useSelector } from "react-redux";
import MapZones from "../orders_online/MapZones";
import SalesChart from "./SalesChart";
import GroupMap from "../orders_online/GroupMap";

export default function Reports() {

    const appData = useSelector((state) => state.appData);

    const [months] = useState([
        { month: "Enero", val: 1 },
        { month: "Febrero", val: 2 },
        { month: "Marzo", val: 3 },
        { month: "Abril", val: 4 },
        { month: "Mayo", val: 5 },
        { month: "Junio", val: 6 },
        { month: "Julio", val: 7 },
        { month: "Agosto", val: 8 },
        { month: "Septiembre", val: 9 },
        { month: "Octubre", val: 10 },
        { month: "Noviembre", val: 11 },
        { month: "Diciembre", val: 12 }
    ]);

    const [monthSelected, setMonth] = useState(false);
    const [yearSelected, setYear] = useState(false);
    const [years, setYears] = useState(false);
    const [orders, setOrders] = useState([]);


    useEffect(() => {

        const currentYear = new Date().getFullYear();
        let yearsArr = [currentYear];

        for (let i = currentYear; i >= 1950; i--) {
            yearsArr.push(i);
        }
      
        setYears(yearsArr);

    }, []);




    async function applyDates() {
        const resultDates = utils.getMonthStartEnd(monthSelected, yearSelected);

        const ordersR = await pos.getOrdersByDay(resultDates.start, resultDates.end);

        setOrders(ordersR);
    }

    const processSalesData = (salesData) => {

        const productSales = {};

        // Procesa los datos de ventas para acumular las cantidades por producto
        salesData.forEach(sale => {
            sale.items.forEach(item => {
                if (productSales[item.name]) {
                    productSales[item.name] += item.quantity || 1;
                } else {
                    productSales[item.name] = item.quantity || 1;
                }
            });
        });

        const sortedProducts = Object.entries(productSales)
            .sort(([, a], [, b]) => b - a) // Ordenar por cantidad vendida (valor)
            .slice(0, 10);

        const top10Products = Object.fromEntries(sortedProducts);

        const labels = Object.keys(top10Products);
        const data = Object.values(top10Products);


        return {
            labels,
            datasets: [
                {
                    data
                },
            ],
        };

    };


    const typeOrderData = (salesData) => {

        const typeSale = {};

        // Procesa los datos de ventas para acumular las cantidades por producto
        salesData.forEach(sale => {
            if (sale.hasOwnProperty('typeDeliver')) {

                if (typeSale['online']) {
                    typeSale['online'] += 1;
                } else {
                    typeSale['online'] = 1;
                }
            } else {
                if (typeSale['restaurante']) {
                    typeSale['restaurante'] += 1
                } else {
                    typeSale['restaurante'] = 1
                }
            }

        });

        // Prepara los datos para la gráfica de dona
        const labels = Object.keys(typeSale);
        const data = Object.values(typeSale);

        return {
            labels,
            datasets: [
                {
                    data
                },
            ],
        };
    };

    const typeOrderOnlineData = (salesData) => {

        const typeSale = {};

        // Procesa los datos de ventas para acumular las cantidades por producto
        salesData.forEach(sale => {
            if (sale.hasOwnProperty('typeDeliver')) {

                if (sale.typeDeliver === 'P') {
                    if (typeSale['Envio']) {
                        typeSale['Envio'] += 1;
                    } else {
                        typeSale['Envio'] = 1;
                    }
                } else if (sale.typeDeliver === 'S') {
                    if (typeSale['Recoger']) {
                        typeSale['Recoger'] += 1;
                    } else {
                        typeSale['Recoger'] = 1;
                    }
                }

            }

        });

        // Prepara los datos para la gráfica de dona
        const labels = Object.keys(typeSale);
        const data = Object.values(typeSale);

     

        return {
            labels,
            datasets: [
                {
                    data
                },
            ],
        };
    };

    const onlineDelivery = (salesData) => {

        let ordersToDeliver = salesData.filter((order) => order.hasOwnProperty('typeDeliver') && order.typeDeliver === 'P' && order.deliverAt);

       
        return ordersToDeliver;
    };

    const groupsDelivery = (salesData) =>{

        let ordersToDeliver = salesData.filter((order) => order.hasOwnProperty('typeDeliver') && order.typeDeliver === 'P'  && order.deliverAt);

        const locationsMap = [];

        for(let lm of ordersToDeliver){
           if(lm.address && lm.address.location){
            locationsMap.push(lm.address.location)
           }
        }

        return utils.groupLocationsByProximity(locationsMap,1);

    }


    const categorieSalesData = (salesData) => {
        const categoriesSales = {};

        // Procesa los datos de ventas para acumular las cantidades por producto
        salesData.forEach(sale => {
            sale.items.forEach(item => {
                let categName = appData.categories.filter((c) => c.id === item.categorie)[0].name;

                if (categoriesSales[categName]) {
                    categoriesSales[categName] += item.quantity || 1;
                } else {
                    categoriesSales[categName] = item.quantity || 1;
                }
            });
        });

        // Prepara los datos para la gráfica de dona
        const labels = Object.keys(categoriesSales);
        const data = Object.values(categoriesSales);

        return {
            labels,
            datasets: [
                {
                    data
                },
            ],
        };
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col s1 left-align">
                    <Link to='/admin' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className="col s11">
                    <h4>Reportes</h4>
                </div>
                <div className="col s12">
                    <div className="divider" />
                </div>
            </div>
            <div className="row">
                <div className="col s6 m3 l3">
                    <label>Mes:</label>
                    <select value={monthSelected} onChange={(e) => setMonth(e.target.value)}
                        className="browser-default"
                    >
                        <option>Seleccione una opción</option>
                        {
                            months.map((m) => {
                                return (<option value={m.val} key={m.month}>{m.month}</option>)
                            })
                        }
                    </select>

                </div>
                <div className="col s6 m3 l3">
                    <label>Año:</label>
                    <select value={yearSelected} onChange={(e) => setYear(e.target.value)}
                        className="browser-default"
                    >
                        <option>Seleccione una opción</option>
                        {
                            years && years.map((y, i) => {
                                return (<option value={y} key={i}>{y}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="col s12 left-align" style={{ marginTop: '1rem' }}>
                    <button className="btn green waves-effect"
                        onClick={() => {
                            applyDates();
                        }}
                    >Obtener Reportes</button>
                </div>
            </div>

            <div className="row">
                {
                    orders && orders.length ?
                        <div className="col s12">
                            <div className="row">
                                <div className="col s12">
                                    <div className="card">
                                        <div className="card-content">
                                            <span className="card-title">Ventas por Día</span>
                                            <LineChart salesData={orders} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col s12">
                                    <div className="card">
                                        <div className="card-content">
                                            <span className="card-title">Productos vendidos</span>
                                            <SalesChart orders={orders} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col s12 m6 l6">
                                    <div className="card">
                                        <div className="card-content">
                                            <span className="card-title">Top 10 Productos vendidos</span>
                                            <DoughnutChart salesData={processSalesData(orders)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col s12 m6 l6">
                                    <div className="card">
                                        <div className="card-content">
                                            <span className="card-title">Categoría más vendidas</span>
                                            <DoughnutChart salesData={categorieSalesData(orders)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col s12 m6 l6">
                                    <div className="card">
                                        <div className="card-content">
                                            <span className="card-title">Ordenes</span>
                                            <DoughnutChart salesData={typeOrderData(orders)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col s12 m6 l6">
                                    <div className="card">
                                        <div className="card-content">
                                            <span className="card-title">Ordenes Online</span>
                                            <DoughnutChart salesData={typeOrderOnlineData(orders)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col s12">
                                    <div className="card">
                                        <div className="card-content">
                                            <span className="card-title">Zonas de entrega</span>
                                            <GroupMap groups={groupsDelivery(orders)}></GroupMap>
                                        </div>
                                    </div>

                                </div>

                                <div className="col s12">
                                    <div className="card">
                                        <div className="card-content">
                                            <span className="card-title">Puntos de entrega</span>
                                            <MapZones orders={onlineDelivery(orders)} zones={false}></MapZones>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                        : null

                }


            </div>
        </div>
    )
}