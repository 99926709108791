import { useEffect, useState } from 'react';
import * as fb from '../firebase_utils';
import * as validations from '../firebase_utils/validations';
import * as utils from '../utils';
import * as pos from '../firebase_utils/pos';
import noImage from '../assets/noImage.jpg';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import { NumericFormat } from 'react-number-format';
import LazyLoad from 'react-lazy-load';
import { useSelector } from 'react-redux';

export default function Pos() {

    const appData = useSelector((state) => state.appData);

    const [categories, setCategories] = useState([]);
    const [moneyR, setMoneyR] = useState('');
    const [order, setOrder] = useState([]);
    const [change, setChange] = useState('');
    const [discount, setDiscount] = useState(0);
    const [appliedDiscount, setAppliedDiscount] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('');
    const [productsFilter, setProductsFilter] = useState([]);

    useEffect(() => {
        getCateories();
        M.Modal.init(document.querySelectorAll('.modal'), {
            dismissible: false,
            onCloseEnd: () => {
                setMoneyR('');
                setDiscount(0);
                setAppliedDiscount(false);
                setChange('')
            }
        });
    }, []);

    useEffect(() => {
        getChange();
    }, [moneyR])

    useEffect(() => {
        if (discount >= 0) {
            const disc = validations.applyDiscount(validations.getTotalOrder(order), discount);
            setAppliedDiscount(disc);
        } else {
            setAppliedDiscount(false);
        }

    }, [discount]);

    useEffect(() => {
        if (appliedDiscount >= 0) {
            getChange();
        }
    }, [appliedDiscount])



    function searchByNameOrCode(searchText) {
        // Convertir el texto de búsqueda a minúsculas para una búsqueda insensible a mayúsculas y minúsculas
        const lowerCaseSearchText = searchText.toLowerCase();

        // Filtrar el arreglo basado en el campo name
        const filterList = appData.products.filter(item => item.name.toLowerCase().includes(lowerCaseSearchText) || item.cbar.toString().includes(lowerCaseSearchText));

        if (filterList.length) {
            setProductsFilter(filterList);
            M.Modal.getInstance(document.getElementById('modalPosFilter')).open();
            setFilter('');
        }

    }

    const addProduct = (nP) => {

        order.push({ ...nP, quantity: 1 });

        setOrder([...order]);
    }

    const deleteProduct = (p) => {
        if (window.confirm('¿Está seguro de eliminar este producto de la orden?')) {
            const indexItem = order.findIndex((prod) => prod.id === p.id);

            order.slice(0, indexItem);

            const newOrder = [
                ...order.slice(0, indexItem),
                ...order.slice(indexItem + 1)
            ];

            setOrder(newOrder);

        }
    }

    const getCateories = async () => {
        setCategories(await fb.getAllCategories());
    }

    function getChange() {

        let total = validations.getTotalOrder(order);

        if (appliedDiscount >= 0) {
            total = appliedDiscount;
        }

        const changeF = (parseFloat(moneyR) - parseFloat(total));

        if (changeF >= 0) {
            setChange(changeF);
        } else {
            setChange('');
        }

    }


    const saveOrder = async () => {

        setLoading(true);

        let dataOrder = {};

        dataOrder.items = order;
        dataOrder.discount = parseInt(discount);
        dataOrder.moneyR = parseFloat(moneyR);
        dataOrder.change = change;

        if (await pos.addOrder(dataOrder)) {
            M.Modal.getInstance(document.getElementById('modalPos')).close();
            setOrder([]);
        }

        setLoading(false);
    }

    return (

        <body>
            <main>
                <div className="row">
                    <div className="col s10">
                        <h4>Punto de Venta</h4>
                    </div>
                    <div className="col s2">
                        <Link to="/admin" className='black-text'><h4><i className="material-icons right cursor_pointer">exit_to_app</i></h4></Link>
                    </div>
                    <div className="col s12">
                        <div className="divider"></div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col s12 m12 l7'>
                        <div className='row contentProducts' >
                            {
                                appData.products.map((p) => {
                                    return (
                                        <div className='col s4' key={p.id}>
                                            <div className="card">
                                                <div className="card-content">
                                                    <div className='row'>
                                                        {
                                                            p.img ?
                                                                <div className='col s12 center-align'>

                                                                    <LazyLoad once>
                                                                        <img src={p.img ? p.img : noImage} className='responsive-img imgProductPos' />
                                                                    </LazyLoad>
                                                                </div>

                                                                : null
                                                        }
                                                        <div className='col s12'>
                                                            <span className='truncate' style={{ width: '100px' }}>{p.name}</span>
                                                            <div className='divider' />
                                                        </div>
                                                        <div className='col s12 boldText'>
                                                            ${p.price}
                                                        </div>
                                                        <div className='col s12 right-align'>
                                                            <button className='btn green waves-effect'
                                                                onClick={() => {
                                                                    addProduct(p);
                                                                }}
                                                            ><i className='material-icons'>add</i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='col s12 m12 l5'>
                        <div className='row contentProducts' >
                            <div className='col s12'>
                                <h5>Productos</h5>
                                <div className='divider' />
                            </div>
                            <div className='col s12'>
                                {
                                    order.length ?
                                        <table className='responsive-table'>
                                            <thead>
                                                <tr>
                                                    <th>Producto</th>
                                                    <th>Precio</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>

                                                {
                                                    order.map((v) => {
                                                        return (
                                                            <tr>
                                                                <td><span className='truncate'>{v.quantity} x {v.name}</span></td>
                                                                <td>{v.price}</td>
                                                                <td className='right-align'>${utils.parseMoney((v.quantity * v.price).toFixed(2))}</td>
                                                                <td>

                                                                    <button className='btn waves-effect red'
                                                                        onClick={() => {
                                                                            deleteProduct(v);
                                                                        }}
                                                                    ><i className='material-icons'>delete</i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan={2} className='right-align boldText'> Total: </td>
                                                    <td colSpan={2} className='boldText'>

                                                        <h4> ${utils.parseMoney(validations.getTotalOrder(order))}</h4>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5} className='right-align'>
                                                        <button className='btn waves-effect'
                                                            disabled={!order.length}

                                                            onClick={() => {
                                                                M.Modal.getInstance(document.getElementById('modalPos')).open();
                                                            }}
                                                        >
                                                            Pagar <i className='material-icons left'>attach_money</i>
                                                        </button>

                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div id="modalPos" className="modal">
                    <div className="modal-content">
                        <div className='row'>
                            <div className='input-field col s12'>
                                <NumericFormat
                                    id="price"
                                    thousandSeparator={','}
                                    decimalSeparator="."
                                    value={moneyR}
                                    isNumericString
                                    decimalScale={2}
                                    fixedDecimalScale
                                    isAllowed={validations.isValueAllowed}
                                    prefix="$"
                                    allowNegative={false}
                                    onValueChange={(values) => {
                                        setMoneyR(values.value);
                                    }}
                                />
                                <label htmlFor="price">Importe Recibido: </label>
                            </div>
                            <div className='input-field col s12'>

                                <input type="text" id="discount" value={discount}
                                    onChange={(e) => {

                                        if ((!isNaN(e.target.value) && parseInt(e.target.value) <= 100
                                            && parseInt(e.target.value) > 0) || e.target.value === '') {
                                            setDiscount(e.target.value);
                                        } else {
                                            setDiscount(0)
                                        }

                                    }}
                                ></input>
                                <label htmlFor='discount'>Descuento:</label>
                            </div>


                            <div className='col s12'>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Total:</td>
                                            <td className='right-align'>${utils.parseMoney(validations.getTotalOrder(order))}</td>
                                        </tr>
                                        {
                                            discount ?
                                                <tr>
                                                    <td>Descuento:</td>
                                                    <td className='right-align'>{discount} %</td>
                                                </tr>
                                                : null
                                        }

                                        {
                                            discount ?
                                                <tr>
                                                    <td>Total - Descuento:</td>
                                                    <td className='right-align'> ${utils.parseMoney(parseFloat(appliedDiscount).toFixed(2))}</td>
                                                </tr>
                                                : null
                                        }

                                        <tr>
                                            <td>Cambio:</td>
                                            <td className='right-align'> {change !== '' ? `$${utils.parseMoney(parseFloat(change).toFixed(2))}` : ''}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>


                            <div className='col s12' style={{ marginBottom: '1rem' }}>
                                <div className='divider' />
                            </div>

                            <div className='col s6 center-align'>
                                <button className='btn red waves-effect'
                                    onClick={() => {
                                        M.Modal.getInstance(document.getElementById('modalPos')).close();
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                            <div className='col s6 center-align'>
                                <button className='btn green waves-effect'
                                    disabled={change === '' || loading}
                                    onClick={() => {
                                        saveOrder();
                                    }}
                                >
                                    Terminar Compra
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="modalPosFilter" className="modal">
                    <div className="modal-content">
                        <div className='row'>
                            <div className='col s11'>
                                <h4>Filtro</h4>
                            </div>
                            <div className='col s1 right-align'>
                                <i className='material-icons cursor_pointer' onClick={() => {
                                    M.Modal.getInstance(document.getElementById('modalPosFilter')).close();
                                }
                                }>close</i>
                            </div>
                            <div className='col s12'>
                                <div className='divider' />
                            </div>
                            <div className='col s12'>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Código</td>
                                            <td>Nombre</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            productsFilter.map((p) => {
                                                return (
                                                    <tr className='cursor_pointer sale_order'
                                                        key={p.cbar}
                                                        onClick={() => {
                                                            addProduct(p);
                                                            M.Modal.getInstance(document.getElementById('modalPosFilter')).close();
                                                        }}
                                                    >
                                                        <td>{p.cbar}</td>
                                                        <td>{p.name}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <footer className="page-footer  cyan lighten-5">
                <div>
                    <div className="row">
                        <div className="input-field col s12 m12 l6">
                            <i className="material-icons prefix black-text">search</i>
                            <input type='text' value={filter} onChange={(e) => setFilter(e.target.value)} id="filter"

                                onKeyUp={(e) => {

                                    if ((e.key.toLocaleLowerCase() === 'arrowdown'
                                        || e.key.toLocaleLowerCase() === 'enter')
                                        && filter !== "") {
                                        searchByNameOrCode(filter);
                                    }
                                }}

                            />
                            <label htmlFor='filter'>Buscar</label>
                        </div>
                    </div>
                </div>
            </footer>
        </body>
    )

}