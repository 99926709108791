import React, { useState } from "react";
import { MapContainer, TileLayer, Circle, Popup, Marker } from "react-leaflet";
import * as utils from '../utils';
import "leaflet/dist/leaflet.css";

function GroupMap({ groups }) {
    const [location] = useState(utils.CENTER_INIT_MAP); // Ubicación por defecto


    return (
        <MapContainer center={location} zoom={13} style={{ height: '500px', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {/* Muestra cada grupo como un círculo */}
            {groups.map((group, index) => {
                // Calcula el centro del grupo
                const centerLat = group.reduce((sum, loc) => sum + loc[0], 0) / group.length;
                const centerLon = group.reduce((sum, loc) => sum + loc[1], 0) / group.length;
                const center = [centerLat, centerLon];

                // Radio de cada círculo, por ejemplo, en metros
                const radius = 200; // El radio puede depender del tamaño del grupo

                // Genera un color aleatorio para cada grupo
                const color = utils.getRandomColor();

                return (
                    <React.Fragment>
                        <Marker position={center}>
                            <Popup>
                                <strong>Pedidos:</strong> {group.length}
                            </Popup>
                        </Marker>

                        <Circle
                            key={index}
                            center={center}
                            radius={radius} // Ajusta el radio según la distancia
                            pathOptions={{ color }}
                        />
                    </React.Fragment>
                );
            })}
        </MapContainer>
    );
}

export default GroupMap;