import { useEffect, useState } from "react";
import * as pos from '../firebase_utils/pos';
import * as validations from '../firebase_utils/validations';
import { Link } from "react-router-dom";
import M from 'materialize-css';
import * as utils from '../utils';
import ModalReceipt from '../commons_components/modalReceipt';


export default function Sales() {

    const [start, setStart] = useState(new Date());
    const [end, setEnd] = useState(new Date());
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState(false);
    const [receiptAlert, setReceipt] = useState(false);

    useEffect(() => {
        M.Modal.init(document.querySelectorAll('.modal'), {
            dismissible: false,
            onCloseEnd: () => {
                setOrder(false);
            }
        });
    }, [])


    const getOrder = async () => {

        const ordersR = await pos.getOrdersByDay(utils.parseDate(start), utils.setEndOfDay(utils.parseDate(end)));

        setOrders(ordersR);

        if (!ordersR.length) {
            M.toast({ html: "No se encontro información.", classes: 'red darken-4' })
        }
    }

    useEffect(() => {

        if (new Date(start) > new Date(end)) {
            setEnd(false);
        }

    }, [start])

    return (
        <div className="container">

            <div className="row">
                <div className="col s1 left-align">
                    <Link to='/admin' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className="col s11">
                    <h4>Ventas</h4>
                </div>
                <div className="col s12">
                    <div className="divider"></div>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s3">
                    <input type="date" id="start" value={start} onChange={(e) => {
                        setStart(e.target.value);
                    }} ></input>
                    <label htmlFor="start">Fecha Inicio:</label>
                </div>
                <div className="input-field col s3">
                    <input type="date" id="end" value={end}
                        min={start}
                        onChange={(e) => {

                            if (e.target.value === "") {
                                setEnd(false);
                            } else {
                                setEnd(e.target.value);
                            }

                        }} ></input>
                    <label htmlFor="end">Fecha Fin:</label>
                </div>
                <div className="col s12 left-align">
                    <button className="btn waves-effect green"
                        onClick={() => {

                            getOrder();
                        }}
                    >
                        Buscar
                    </button>
                </div>
            </div>

            <div className="row" >

                <table className="responsive-table">
                    <thead>
                        <tr>
                            <td>Fecha</td>
                            <td>Artículos</td>
                            <td>Total</td>
                            {/* <td>Descuento</td> */}
                            {/* <td>SubTotal</td> */}
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>


                        {
                            orders.map((order) => {
                                return (
                                    <tr className="cursor_pointer sale_order" key={order.id}>
                                        <td> {utils.formatDate(new Date(order.timestamp).toString())}</td>
                                        <td className="">{order.items.length}</td>
                                        <td> ${utils.parseMoney(parseFloat(validations.getTotalOrder(order.items), order.discount).toFixed(2))}</td>
                                        {/* <td> {order.discount > 0 ? `${order.discount}%` : '-'}</td> */}
                                        {/* <td>{
                                            `$${utils.parseMoney(parseFloat(validations.applyDiscount(validations.getTotalOrder(order.items), order.discount)).toFixed(2))}`
                                        }</td> */}
                                        <td>
                                            <button className="btn-flat waves-effect"
                                                onClick={() => {
                                                    setOrder(order);
                                                    if (order.items.length) {
                                                        M.Modal.getInstance(document.getElementById('modalItems')).open();
                                                    }

                                                }}
                                            ><i className="material-icons">remove_red_eye</i></button>

                                            <button className="btn-flat waves-effect"
                                                onClick={() => {

                                                    setReceipt(order);
                                                }}
                                            ><i className="material-icons">receipt</i></button>
                                        </td>

                                    </tr>

                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div id="modalItems" className="modal">
                <div className="modal-content">
                    <div className='row'>
                        <div className='col s11'>
                            <h4>Articulos</h4>
                        </div>
                        <div className='col s1 right-align'>
                            <i className='material-icons cursor_pointer' onClick={() => {
                                M.Modal.getInstance(document.getElementById('modalItems')).close();
                            }
                            }>close</i>
                        </div>
                        <div className='col s12'>
                            <div className='divider' />
                        </div>
                        <div className='col s12'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>Código</td>
                                        <td>Nombre</td>
                                        <td>Precio</td>
                                        <td className="center-align">Cantidad</td>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        order && order.items.map((p) => {
                                            return (
                                                <tr className='sale_order'
                                                    key={p.cbar}
                                                >
                                                    <td>{p.cbar}</td>
                                                    <td>{p.name}</td>
                                                    <td>${utils.parseMoney(p.price)}</td>
                                                    <td className="center-align">{p.quantity || 1}</td>
                                                    <td>${utils.parseMoney((parseFloat(p.price) * (p.quantity || 1)).toFixed(2))}</td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

            <ModalReceipt receiptAlert={receiptAlert} setReceipt={setReceipt} />

        </div>
    )
}