import React, { useEffect, useState } from 'react';
import noImage from '../assets/noImage.jpg';
import M from 'materialize-css';
import * as validations from '../firebase_utils/validations';
import * as utils from '../utils';
import * as pos from '../firebase_utils/pos';
import * as fb_utils from '../firebase_utils';
import OrderMap from './orderMap';
import ModalAlert from '../commons_components/modalAlert';
import { useSelector } from 'react-redux';
import LazyLoad from 'react-lazy-load';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import CountdownTimer from '../pos_components/CountdownTimer';


export default function PosOnline() {

    const appData = useSelector((state) => state.appData);


    const [secctionActive, setSectionActive] = useState('');
    const [order, setOrder] = useState([]);
    const [dataAlert, setDataAlert] = useState(false);
    const [stepOrder, setStepOrder] = useState(1);
    const [typeDeliver, setTypeDeliver] = useState('S');
    const [contactOrder, setContactOrder] = useState({
        name: "",
        phone: "",
        references: ""
    });
    const [finishCountDown, setFinishCountDown] = useState(false);
    const [logo, setLogo] = useState(false);

    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState(false);

    const [waitToAdd, setWaitToAdd] = useState([]);

    useEffect(() => {
        if (appData.settings) {
            getLogo(appData.settings);
        }
    }, [appData]);


    const getLogo = async (apSettings) => {
        if (apSettings) {
            let logoImg = '';
            if (apSettings.img !== "") {
                logoImg = await fb_utils.getImageLogo(apSettings.img);
                setLogo(logoImg);
            }
        }
    }

    const saveOrder = async () => {

        setLoading(true);

        let dataOrder = {};

        dataOrder.items = order;
        dataOrder.contactOrder = contactOrder;
        dataOrder.typeDeliver = typeDeliver;

        if (typeof address === 'object' && typeDeliver === 'P') {
            dataOrder.address = { location: [address.lat, address.lng] }
        }

        if (Array.isArray(address) && typeDeliver === 'P') {
            dataOrder.address = { location: address }
        }

        if (await pos.addOrderOnline(dataOrder)) {
            setOrder([]);
            setStepOrder(4);
        }

        setLoading(false);

    }


    useEffect(() => {

        M.Sidenav.init(document.querySelectorAll('.sidenav'), {});

        M.FloatingActionButton.init(document.querySelectorAll('.fixed-action-btn'), {});

        M.Modal.init(document.querySelectorAll('.modal'), {
            onCloseEnd: () => {
                setStepOrder(1);
                setContactOrder({
                    name: "",
                    phone: ""
                })
            }

        });
    }, [])



    const addProduct = (nP) => {


        const waitToAddQuantity = getWaitToOrder(nP);

        if (waitToAddQuantity) {

            for (let i = 0; i < waitToAddQuantity; i++) {
                order.push({ ...nP, quantity: 1 });
            }

        } else {
            order.push({ ...nP, quantity: 1 });
        }

        deleteProductOut(nP);

        //elimina los demas toast

        M.Toast.dismissAll();

        M.toast({ html: `Producto Agregado <i class="material-icons right">check_circle</i>`, classes: 'green darken-3' })

        setOrder([...order]);
    }

    const addProductOut = (nP) => {

        const existItem = waitToAdd.findIndex((p) => p.id === nP.id);

        if (existItem >= 0) {
            nP = waitToAdd[existItem];
            nP.quantity++;
        } else {
            waitToAdd.push({ ...nP, quantity: 2 });
        }

        setWaitToAdd([...waitToAdd]);
    }


    const restProductOut = (p) => {

        const indexItem = waitToAdd.findIndex((prod) => prod.id === p.id);

        if (indexItem >= 0) {
            p = waitToAdd[indexItem];

            p.quantity--;

            setWaitToAdd([...waitToAdd]);
        }

    }

    const deleteProduct = (i) => {

        setDataAlert({
            message: `¿Está seguro de eliminar este producto de la orden?`,
            actionConfirm: () => {

                order.slice(0, i);

                const newOrder = [
                    ...order.slice(0, i),
                    ...order.slice(i + 1)
                ];

                try {
                    if (!newOrder.length) {
                        M.Modal.getInstance(document.getElementById('modalOrder')).close();
                    }
                } catch (error) {

                }
                setOrder(newOrder);
            }
        })

    }

    const deleteProductOut = (p) => {

        const indexItem = waitToAdd.findIndex((prod) => prod.id === p.id);

        waitToAdd.slice(0, indexItem);

        const newWaitToAdd = [
            ...waitToAdd.slice(0, indexItem),
            ...waitToAdd.slice(indexItem + 1)
        ];

        setWaitToAdd(newWaitToAdd);

    }

    const showButtons = (p) => {
        let showButton = false;


        if (
            appData.settings
            && (appData.settings.toGo || appData.settings.pickup)
            && (utils.isOpenNow(appData.settings.schedulePickUp)
                || utils.isOpenNow(appData.settings.scheduleToGo))
        ) {
            showButton = true;
        }

        if (p.withInventory && (p.inventory <= 0 || !p.inventory)) {
            showButton = false;
        }

        return showButton;
    }

    const stepOrderButton = () => {
        if (stepOrder === 1) {
            return <React.Fragment>
                <div className='col s6 right-align'>
                    <button className="waves-red waves-effect btn-flat"
                        onClick={() => {
                            M.Modal.getInstance(document.getElementById('modalOrder')).close();
                        }}><i className='material-icons left'>keyboard_backspace</i>Atrás</button>
                </div>
                <div className='col s6 left-align'>
                    <button className="btn waves-effect bgBrand secundary boldText"
                        onClick={() => {
                            setStepOrder(2)
                        }}
                    ><i className='material-icons right '>arrow_forward</i>Seguir</button>
                </div>
            </React.Fragment>

        }

        if (stepOrder === 2) {
            return <React.Fragment>
                <div className='col s6 right-align'>
                    <button className="waves-effect waves-red  btn-flat"
                        onClick={() => {
                            setStepOrder(1);
                            setTimeout(() => {
                                M.updateTextFields();
                            }, 100)

                        }}><i className='material-icons left'>keyboard_backspace</i>Atrás</button>
                </div>

                <div className='col s6 left-align'>
                    <button className="btn waves-effect  bgBrand secundary boldText"
                        disabled={!typeDeliver}
                        onClick={() => {
                            setStepOrder(3)

                        }}><i className='material-icons right'>send</i>Seguir</button>
                </div>
            </React.Fragment>
        }

        if (stepOrder === 3) {

            return <React.Fragment>
                <div className='col s12 center-align'>
                    <p>
                        Al realizar la orden, usted está de acuerdo con nuestro
                        <Link to="/aviso-de-privacidad" target="_blank" rel="noopener noreferrer"
                            className='blue-text' style={{ marginLeft: '8px' }}
                        >
                            aviso de privacidad
                        </Link>.
                    </p>
                </div>
                <div className='col s6 right-align'>
                    <button className="waves-red waves-effect btn-flat"
                        onClick={() => {
                            setStepOrder(2);
                            setAddress(false);
                            setContactOrder({
                                name: "",
                                phone: "",
                                references: ""
                            })

                        }}><i className='material-icons left'>keyboard_backspace</i>Atrás</button>
                </div>

                <div className='col s6 left-align'>
                    <button className="btn waves-effect bgBrand secundary boldText"
                        disabled={loading || contactOrder.name.trim() === "" || contactOrder.phone.trim() === ""

                            || contactOrder.phone.trim().length < 10 ||
                            (typeDeliver === 'P' && !address)
                        }
                        onClick={() => {
                            saveOrder();
                        }}><i className='material-icons right'>check_circle</i>Ordenar</button>
                </div>
            </React.Fragment>

        }

        return null;
    }

    const stepOrderView = () => {
        if (stepOrder === 1) {
            return <div className='col s12'>
                {
                    order.length ?
                        <table className=''>
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Precio</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>

                                {
                                    order.map((v, i) => {
                                        let impar = (i % 2 === 0) ? true : false;

                                        return (
                                            <React.Fragment key={i}>
                                                <tr className={impar ? 'grey lighten-4' : ''}>
                                                    <td>
                                                        <span className='boldText primary'>{v.name}</span>
                                                    </td>
                                                    <td> ${utils.parseMoney((v.price * 1).toFixed(2))}</td>
                                                    <td className='center-align'>
                                                        <span><button className='btn-flat waves-effect'
                                                            onClick={() => {

                                                                deleteProduct(i);

                                                            }}
                                                        >
                                                            <i className='material-icons red-text darken-4'>clear</i>
                                                        </button></span>
                                                    </td>
                                                </tr>
                                                <tr className={impar ? 'grey lighten-4' : ''}
                                                    hidden={!v.notes}
                                                >
                                                    <td colSpan={3}>
                                                        <div className='row'>
                                                            <div className='input-field col s10 offset-s2'>
                                                                <input type='text' id={`${v.id}_${i}`} maxLength={150}
                                                                    value={v.indications} onChange={(e) => {
                                                                        order[i].indications = e.target.value;
                                                                        setOrder(order);
                                                                    }}
                                                                ></input>
                                                                <label htmlFor={`${v.id}_${i}`}>Indicaciones:</label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                <tr>
                                    <td colSpan={3} className='boldText center-align'>

                                        <h5 className='boldText'>Total: <span className='primary'>${utils.parseMoney(validations.getTotalOrder(order))}</span></h5>

                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        : null
                }
            </div>
        }

        if (stepOrder === 2) {
            return (
                <div className='col s12'>
                    <div className='row'>

                        {

                            appData.settings && appData.settings.pickup && utils.isOpenNow(appData.settings.schedulePickUp) ?

                                <div className='col s12 m12 l6 xl6 center-align'>
                                    <div className={`cardTypeOrder ${typeDeliver === 'S' ? 'active' : ''}`}
                                        onClick={() => {
                                            setTypeDeliver('S');
                                        }}
                                    >
                                        <p><i className='material-icons ft-50px'>store</i></p>
                                        <p>Acordar Entrega</p>
                                    </div>
                                </div> : null
                        }

                        {
                            appData.settings && appData.settings.toGo && utils.isOpenNow(appData.settings.scheduleToGo) ?

                                <div className='col s12 m12 l6 xl6 center-align'>
                                    <div className={`cardTypeOrder ${typeDeliver === 'P' ? 'active' : ''}`}
                                        onClick={() => {
                                            setTypeDeliver('P');
                                        }}
                                    >
                                        <p><i className='material-icons ft-50px'>local_shipping</i></p>
                                        <p>Enviar a Domicilio</p>
                                    </div>
                                </div>

                                : null
                        }



                    </div>
                </div>
            )
        }

        if (stepOrder === 3) {
            return (
                <div className='col s12'>

                    <div className='row' style={{ marginTop: '1rem' }}>
                        <div className='input-field col s12'>
                            <input type="text" id="name" value={contactOrder.name}
                                onChange={(e) => {
                                    setContactOrder({ ...contactOrder, name: e.target.value });
                                }
                                } />
                            <label htmlFor='name'>Nombre:</label>
                        </div>
                        <div className='input-field col s12'>
                            <input type="text" id="name" value={contactOrder.phone}
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                                        setContactOrder({ ...contactOrder, phone: e.target.value });
                                    }
                                }
                                } />
                            <label htmlFor='name'>Teléfono:</label>
                            <span className="helper-text"><i className='material-icons left'>info</i>Se intentara comunicar contigo para confirmar el pedido.</span>
                        </div>
                        {
                            typeDeliver === 'P' ?
                                <React.Fragment>

                                    <OrderMap setAddress={setAddress} />

                                    <div className='input-field col s12'>
                                        <input type="text" id="references"
                                            value={contactOrder.references}
                                            onChange={(e) => {
                                                setContactOrder({ ...contactOrder, references: e.target.value })
                                            }}
                                        >
                                        </input>
                                        <label htmlFor='references'>Referencias:</label>
                                        <span className='helper-text'><i className='material-icons left'>info</i>Ayudanos a dar con tu dirección ejemplo: <span className='boldText'>"Casa dos pisos color naranja, entre calles ...."</span></span>
                                    </div>

                                </React.Fragment>



                                : null
                        }
                    </div>

                </div>
            )
        }

        if (stepOrder === 4) {

            return <div className='col s12'>
                <div className='row'>
                    <div className='col s12 center-align'>
                        <i className='material-icons successOrder'>check_circle</i>
                    </div>
                    <div className='col s12 center-align'>
                        <p>Tu orden ha sido confirmada.</p>
                        <p> En breve nos pondremos en contacto contigo para verificar los detalles.</p>
                        <p className='boldText'>¡Gracias por tu preferencia!</p>
                    </div>
                </div>
            </div>

        }
    }


    const getWaitToOrder = (p) => {
        let flwto = waitToAdd.filter((q) => q.id === p.id);

        if (flwto.length) {
            return flwto[0].quantity;
        }

        return 1;
    }

    const getTitleStep = () => {
        if (stepOrder === 1) {
            return "Resumen"
        }

        if (stepOrder === 2) {
            return "¿Cómo desea su entrega?"
        }

        if (stepOrder === 3) {
            return "Contacto";
        }

        if (stepOrder === 4) {
            return "Orden Confirmada";
        }
    }

    return (
        <>
            <div className="navbar-fixed" style={{ background: '#6b1331' }}>
                <nav className='white'>
                    <div className="nav-wrapper">
                        <Link to={'/'} className="brand-logo center black-text">
                            {
                                logo ?
                                    <img className='responsive-img logoVino' src={logo} />
                                    : null

                            }
                        </Link>
                        <ul id="nav-mobile" className="right">
                            <li><a href="#" data-target="slide-out"
                                onClick={((e) => {
                                    e.preventDefault();
                                    M.Sidenav.getInstance(document.getElementById('slide-out')).open();
                                })}
                            ><i className='material-icons white-text'>menu</i></a></li>
                        </ul>
                    </div>

                </nav>
            </div>

            <ul id="slide-out" class="sidenav">
                {
                    appData.categories.filter((c) => c.active).map((v, i) => {

                        return (
                            <li key={i} ><a href={`#${v.id}`} className={secctionActive === v.id ? 'activeOption sidenav-close' : 'sidenav-close'}>{v.name}</a></li>
                        )
                    })
                }

            </ul>
            <div className="container ">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title> {appData.hasOwnProperty('settings') && appData.settings ? appData.settings.name : 'Menú'}</title>
                </Helmet>
                <div className="row">
                    {
                        !finishCountDown ?

                            <div className='col s12'>
                                <CountdownTimer finishTime={setFinishCountDown} />
                            </div>

                            : null

                    }


                    <div className="col s12" hidden={!finishCountDown}>

                        {
                            appData.categories.filter((c) => c.active).map((v, i) => {

                                return (
                                    <div key={i} id={v.id} className="section">
                                        <h4 className="boldText primary" style={{ marginTop: '1rem' }}>{v.name}</h4>
                                        <div className='divider' style={{ marginBottom: '.5rem' }} />
                                        <div className='row' >
                                            {
                                                appData.products.filter((p) => p.categorie === v.id && p.active).map((p, i) => {
                                                    return (

                                                        <div className='col s12'>
                                                            <div className='row'>
                                                                <div className='col s12 center-align'>
                                                                    <p className='boldText secondary'>{p.name}</p>
                                                                </div>

                                                                {
                                                                    p.img ?
                                                                        <div className='col s12 center-align'>
                                                                            <LazyLoad once>
                                                                                <img src={p.img ? p.img : noImage} className='responsive-img customImgBrand prodIMG' alt={p.name} />
                                                                            </LazyLoad>
                                                                        </div>
                                                                        : null
                                                                }

                                                                <div className='col s12 txtJustify' >
                                                                    {p.description}
                                                                </div>

                                                                <div className='col s12 center-align boldText third'>
                                                                    <p>${p.price}</p>
                                                                </div>

                                                                <div className='col s12'>
                                                                    {
                                                                        showButtons(p) ?
                                                                            <div className='row'>
                                                                                <div className='col s12 m7 center-align'>
                                                                                    {/* { !p.complements || !p.complements.length ?
                                                                     <> */}

                                                                                    <span>
                                                                                        <button className='btn-flat waves-effect'
                                                                                            onClick={() => {
                                                                                                if (getWaitToOrder(p) > 1) {
                                                                                                    restProductOut(p);
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <i className='material-icons'>remove</i>
                                                                                        </button>
                                                                                    </span>
                                                                                    {
                                                                                        getWaitToOrder(p)
                                                                                    }
                                                                                    <span><button className='btn-flat waves-effect'
                                                                                        onClick={() => {
                                                                                            addProductOut(p);
                                                                                        }}
                                                                                    >
                                                                                        <i className='material-icons'>add</i>
                                                                                    </button></span>

                                                                                    {/* </> : null } */}
                                                                                </div>
                                                                                {
                                                                                    appData.settings && (utils.isOpenNow(appData.settings.schedulePickUp) || utils.isOpenNow(appData.settings.scheduleToGo))
                                                                                        ?
                                                                                        <div className='col s12 m5 center-align'>

                                                                                            <button className='btn green waves-effect'
                                                                                                onClick={() => {
                                                                                                    addProduct(p);
                                                                                                }}
                                                                                            >Agregar</button>
                                                                                        </div>
                                                                                        : null

                                                                                }

                                                                            </div> : null}
                                                                </div>

                                                                <div className='col s12'>
                                                                    <div className='divider' />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })}

                        <div className='section' style={{ minHeight: '400px' }}>

                        </div>

                    </div>
                </div>

                <div className="fixed-action-btn">
                    {
                        order.length && appData.settings && (utils.isOpenNow(appData.settings.schedulePickUp) || utils.isOpenNow(appData.settings.scheduleToGo)) ?
                            <button className="btn-floating btn-large red"
                                onClick={(e) => {

                                    e.preventDefault();

                                    let instModal = M.Modal.getInstance(document.getElementById('modalOrder'));

                                    if (instModal && order.length) {
                                        instModal.open();
                                    }
                                }}
                            >
                                <i className="large material-icons">shopping_cart</i>
                            </button>

                            : null
                    }

                </div>

                <div id="modalOrder" className="modal">
                    <div className="modal-content">
                        <div className='row contentProductsOnline' >
                            <div className='col s12 right-align'>
                                <i className='material-icons modal-close'>close</i>
                            </div>
                            <div className='col s12'>
                                <h4 className='primary boldText'>{getTitleStep()}</h4>
                                <div className='divider' />
                            </div>
                            {stepOrderView()}


                            <div className='col s12 center-align' style={{ marginTop: '1rem' }}>
                                {stepOrderButton()}
                            </div>
                        </div>
                    </div>
                </div>

                <ModalAlert dataAlert={dataAlert} setDataAlert={setDataAlert} />

            </div>
        </>
    )
}