
import { useEffect, useState } from "react";
import { collection, onSnapshot, getFirestore } from 'firebase/firestore';
import { Link, useNavigate } from "react-router-dom";
import * as pos from '../firebase_utils/pos';
import ModalReceipt from '../commons_components/modalReceipt';
import ModalAlert from '../commons_components/modalAlert';
import { useSelector } from 'react-redux';

export default function PostRestaurant() {

    const appData = useSelector((state) => state.appData)
    const navigate = useNavigate();

    const [commands, setCommands] = useState([]);
    const [receiptAlert, setReceipt] = useState(false);
    const [dataAlert, setDataAlert] = useState(false);
    const [loadingId, setLoadingId] = useState(false);

    useEffect(() => {
        const db = getFirestore();

        const commandsCollection = collection(db, 'commands');

        const unsubscribe = onSnapshot(commandsCollection, (snapshot) => {
            const commandsList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setCommands(commandsList.sort((a, b) => a.timestamp - b.timestamp));
        });

        // Limpia la suscripción cuando el componente se desmonta
        return () => unsubscribe();
    }, []);

    const saveOrder = (ord) => {

        setDataAlert({
            message: `¿Está seguro de terminar la comanda?`,
            actionConfirm: () => {
                outFunction(ord);
            }
        });

    }

    const outFunction = async (ord) => {

        setLoadingId(ord.id);

        if (await pos.addOrder(ord)) {
            if (!ord.command) {
                await pos.deleteCommand(ord);
            }
        }
        setLoadingId(false);

    }

    const printTables = () => {

        let noTables = 10;

        if (appData.settings && appData.settings.hasOwnProperty('noTables')) {
            noTables = parseInt(appData.settings.noTables);
        }

        const tables = Array.from({ length: noTables }, (_, index) => index);

        return tables;
    }

    const isSameTable = (noTable) => {
        const findendTable = commands.filter((t) => t.table === (noTable + 1) + "");

        if (findendTable !== -1) {
            return findendTable[0];
        }

        return false;
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col s1 left-align">
                    <Link to='/admin' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className="col s11 left-align">
                    <h4>Punto de Venta</h4>
                </div>
                <div className="col s12">
                    <div className="divider"></div>
                </div>
            </div>
            <div className="row">
                <div className="col s6 left-align">
                    <h4>Comandas <i className="material-icons left">chrome_reader_mode</i></h4>
                </div>
                <div className='col s6 right-align'>
                    <Link to='/addCommand' className='btn waves-effect btn-flat boldText waves-green'>Nueva Comanda <i className='material-icons right'>add</i></Link>
                </div>
                <div className="col s12">
                    <p><i className="material-icons left">check_circle</i> Terminar Comanda ***</p>
                    <p><i className="material-icons left">receipt</i> Imprimir Cuenta ***</p>
                </div>
                <div className="col s12">
                    <div className="divider" />
                </div>
            </div>
            <div className="row">

                {
                    printTables().map((t) => {
                        const sameTable = isSameTable(t);

                        return <div className="col s12 m4 l3">
                            <div className={`card  ${sameTable ? 'amber lighten-1' : 'blue-grey darken-1'} `}>
                                <div className="card-content white-text waves-effect"

                                    onClick={() => {
                                        if(sameTable){
                                            navigate(`/editCommand/${sameTable.id}`);
                                        }else{
                                            navigate(`/addCommand/${t+1}`);
                                        }
                                       
                                    }}
                                >
                                    <span className="card-title">Mesa {t + 1}</span>

                                </div>

                                <div className="card-action">
                                    <a href="!#" className="btn waves-effect"
                                    disabled={!sameTable || loadingId}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setReceipt(sameTable);
                                        }}
                                    >
                                        <i className="material-icons">receipt</i></a>
                                    <a href="!#" className="btn waves-effect" style={{marginLeft:'2rem'}}
                                     disabled={!sameTable || loadingId}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            saveOrder(sameTable);
                                        }}
                                    > <i className="material-icons">check_circle</i></a>
                                </div>

                            </div>
                        </div>
                    })
                }


            </div>
            <ModalReceipt receiptAlert={receiptAlert} setReceipt={setReceipt} />
            <ModalAlert dataAlert={dataAlert} setDataAlert={setDataAlert} />
        </div>
    )
}
