import { useEffect } from 'react';
import { collection, onSnapshot, getFirestore } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { setSettings, updateSettings } from '../redux/slices/appSlice';


const useSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {

    const db = getFirestore();

    const unsubscribe = onSnapshot(collection(db, 'settings'), async (snapshot) => {

      snapshot.docChanges().forEach(async (change) => {
        let settings = { id: change.doc.id, ...change.doc.data() };

        if (change.type === 'added') {
          dispatch(setSettings(settings));
        }
        
        if (change.type === 'modified') {
          dispatch(updateSettings(settings));
        }
      });


      const settings = [];

      for (let doc of snapshot.docs) {

        let data = doc.data();
        data.id = doc.id;

        settings.push(data);
      }
      
      dispatch(setSettings(settings[0]));
    });

    return () => unsubscribe(); // Cleanup al desmontar
  }, [dispatch]);
};

export default useSettings;