import React from "react";
import { Link } from "react-router-dom"; // Asumiendo que usas react-router para la navegación

const PageNotFound = () => {
  return (
    <div className="container center-align" style={{ marginTop: "100px" }}>
      <h1 className="red-text text-darken-2">404</h1>
      <h4>Página No Encontrada</h4>
      <p>Lo sentimos, la página que estás buscando no existe.</p>
      
      <div className="row">
        <div className="col s12">
          <Link to="/" className="btn waves-effect waves-light red darken-2">
            Volver al Inicio
          </Link>
        </div>
      </div>
      
      {/* <img 
        src="https://materializecss.com/images/404.svg" 
        alt="Página no encontrada"
        style={{ marginTop: "20px", width: "300px" }}
      /> */}
    </div>
  );
};

export default PageNotFound;
