import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import noImage from '../assets/noImage.jpg';
import * as fb_utils from '../firebase_utils';
import { useSelector } from "react-redux";
import M from 'materialize-css';


function Configs() {
    const appData = useSelector((state) => state.appData);
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (appData.settings) {

            getLogo(appData.settings);

            if (appData.settings && appData.settings.schedulePickUp) {
                setSchedulePickUp(appData.settings.schedulePickUp);
            }

            if (appData.settings && appData.settings.scheduleToGo) {
                setScheduleToGo(appData.settings.scheduleToGo);
            }
            setTimeout(() => {
                M.updateTextFields();
            }, 100);

        }
    }, [appData]);



    const getLogo = async (apSettings) => {
        if (apSettings) {
            let urlImg = '';
            if (apSettings.img !== "") {
                urlImg = await fb_utils.getImageLogo(apSettings.img);
                const newObj = { ...apSettings, imgPre: urlImg };
                setSettings(newObj); 
            }else{
                 setSettings(apSettings); 
            }
        }
    }

    const [schedulePickUp, setSchedulePickUp] = useState({
        lunes: { open: "", close: "", closed: false, order: 1 },
        martes: { open: "", close: "", closed: false, order: 2 },
        miercoles: { open: "", close: "", closed: false, order: 3 },
        jueves: { open: "", close: "", closed: false, order: 4 },
        viernes: { open: "", close: "", closed: false, order: 5 },
        sabado: { open: "", close: "", closed: false, order: 6 },
        domingo: { open: "", close: "", closed: false, order: 7 },
    });

    const handleChange = (day, field, value) => {
        setSchedulePickUp({
            ...schedulePickUp,
            [day]: { ...schedulePickUp[day], [field]: value },
        });
    };


    const [scheduleToGo, setScheduleToGo] = useState({
        lunes: { open: "", close: "", closed: false, order: 1 },
        martes: { open: "", close: "", closed: false, order: 2 },
        miercoles: { open: "", close: "", closed: false, order: 3 },
        jueves: { open: "", close: "", closed: false, order: 4 },
        viernes: { open: "", close: "", closed: false, order: 5 },
        sabado: { open: "", close: "", closed: false, order: 6 },
        domingo: { open: "", close: "", closed: false, order: 7 },
    });

    const handleChangeToGo = (day, field, value) => {
        setScheduleToGo({
            ...scheduleToGo,
            [day]: { ...scheduleToGo[day], [field]: value },
        });
    };

    useEffect(() => {
        if (settings.toGo) {
            setSettings({ ...settings, scheduleToGo: scheduleToGo });
        }
    }, [scheduleToGo]);


    useEffect(() => {
        if (settings.pickup) {
            setSettings({ ...settings, schedulePickUp: schedulePickUp });
        }
    }, [schedulePickUp]);

    const saveSettings = async () => {
        M.Toast.dismissAll();
        setLoading(true);
       
        let id = await fb_utils.updateSettings(settings);

        setSettings({ ...settings, id: id });


        if (settings.img) {
            getLogo(settings);
        }

        setLoading(false);
    }

    return (

        <div className="container" disabled={loading}>
            <div className='row'>
                <div className="col s1 left-align">
                    <Link to='/admin' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className='col s8 left-align'>
                    <h3>Configuraciones</h3>
                </div>
                <div className="col s12 m2 right-align">
                    <button className="btn-flat waves-effect boldText"
                        style={{ marginTop: '2rem' }}

                        onClick={() => {
                            saveSettings();
                        }}
                    > <i className="material-icons left">save</i> Guardar</button>
                </div>
                <div className='col s12'>
                    <div className='divider' />
                </div>
            </div>
            <div className="row">
                <div className="col s12 center-align">

                    <img src={settings.img || settings.imgPre ?
                        typeof settings.img === 'object' ?
                            URL.createObjectURL(settings.img)
                            : settings.imgPre
                        : noImage
                    }
                    alt="logo"
                        className={`rounded cursorPointer ${settings.img || settings.imgPre ? 'responsive-img imgProduct' : ''}`} onClick={() => {
                            document.getElementById('imgFile').click()
                        }} />
                    <p>*Haz clic en la imagen si deseas modificarla.</p>

                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <span className="boldText">Nombre del establecimiento:</span>
                </div>
                <div className="input-field col s12">
                    <input type="text" value={settings.name} onChange={(e) => {
                        setSettings({ ...settings, name: e.target.value });
                    }} id="name"
                    ></input>
                </div>
                <div className="col s12">
                    <div className="divider" />
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <span className="boldText">Contacto:</span>
                </div>
                <div className="input-field col s12">
                    <input type="text" value={settings.phone} onChange={(e) => {
                        setSettings({ ...settings, phone: e.target.value });
                    }} id="phone"></input>
                    <label htmlFor="phone">Teléfono:</label>
                </div>
                <div className="input-field col s12">
                    <input type="text" value={settings.email} onChange={(e) => {
                        setSettings({ ...settings, email: e.target.value });
                    }} id="email" ></input>
                    <label htmlFor="email">Correo electrónico:</label>
                </div>
                <div className="input-field col s12">
                    <input type="text" value={settings.urlWeb} onChange={(e) => {
                        setSettings({ ...settings, urlWeb: e.target.value });
                    }} id="urlWeb"></input>
                    <label htmlFor="urlWeb">Sitio Web:</label>
                </div>
                <div className="col s12">
                    <div className="divider" />
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <span className="boldText">No. de Mesas restaurante:</span>
                </div>
                <div className="input-field col s12">
                    <input type="text" value={settings.noTables} onChange={(e) => {
                        if (/^\d*$/.test(e.target.value) && e.target.value.length <= 200) {
                            setSettings({ ...settings, noTables: e.target.value });
                        }
                    }} id="noTables"></input>
                    <label htmlFor="noTables">No. Mesas:</label>
                    <span className="helper-text">Ingrese el número de mesas disponibles. Cada mesa corresponde a una posible comanda.</span>
                </div>

            </div>

            <div className="row">
                <div className="col s12">
                    <span className="boldText">Ventas en linea:</span>
                </div>
                <div className="col s6">
                    <p>
                        <label>
                            <input type="checkbox" onChange={(e) => {
                                setSettings({ ...settings, toGo: e.target.checked, pickup: e.target.checked, toDeliver: e.target.checked });
                            }} checked={settings.toDeliver || false} />
                            <span>Permitir Ventas en Línea</span>
                        </label>
                    </p>
                </div>
                <div className="col s6">

                    <button className="waves-effect btn-flat"
                        disabled={loading}
                        onClick={() => {
                            saveSettings();
                        }}
                    ><i className="material-icons left">save</i>Guardar</button>

                </div>
                {
                    settings.toDeliver ?
                        <div className="col s12">

                            <div className="row">
                                <div className="col s12 m12 l6 center-align">
                                    <p>
                                        <label>
                                            <input type="checkbox" onChange={(e) => {
                                                setSettings({ ...settings, pickup: e.target.checked })
                                            }} checked={settings.pickup || false} />
                                            <span><i className='material-icons left'>store</i>PickUp (Recoger en Tienda)</span>
                                        </label>
                                    </p>
                                    <div className="divider" />
                                    {
                                        settings.pickup ?


                                            <div className="row">
                                                <div className="col s12">
                                                    {
                                                        Object.keys(schedulePickUp)
                                                            .map(day => ({
                                                                day,
                                                                ...schedulePickUp[day] // Copia inmutable de los valores
                                                            }))
                                                            .sort((a, b) => a.order - b.order) // Orden correcto por `order`
                                                            .map(({ day, open, close, closed, order }, i) => ( // Mapeo para renderizado
                                                                <div key={order} className={i % 2 === 0 ? 'grey lighten-4' : ''}>
                                                                    <h5>{day.charAt(0).toUpperCase() + day.slice(1)}</h5>
                                                                    <p>
                                                                        <label>
                                                                            <span className={closed ? 'red-text darken-4 boldText' : 'boldText'}>Cerrado</span>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={closed}
                                                                                onChange={(e) => handleChange(day, "closed", e.target.checked)}
                                                                            />
                                                                        </label>
                                                                    </p>
                                                                    {!closed && (
                                                                        <>
                                                                            <label>
                                                                                Hora de apertura:
                                                                                <input
                                                                                    type="time"
                                                                                    value={open}
                                                                                    onChange={(e) => handleChange(day, "open", e.target.value)}
                                                                                />
                                                                            </label>
                                                                            <label>
                                                                                Hora de cierre:
                                                                                <input
                                                                                    type="time"
                                                                                    value={close}
                                                                                    onChange={(e) => handleChange(day, "close", e.target.value)}
                                                                                />
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ))
                                                    }
                                                </div>
                                            </div>
                                            : null}
                                </div>
                                <div className="col s12 m12 l6 center-align">
                                    <p>
                                        <label>
                                            <input type="checkbox" onChange={(e) => {
                                                setSettings({ ...settings, toGo: e.target.checked })
                                            }} checked={settings.toGo || false} />
                                            <span><i className='material-icons left'>airport_shuttle</i>Entrega a Domicilio </span>
                                        </label>
                                    </p>
                                    <div className="divider" />
                                    {
                                        settings.toGo ?

                                            <div className="row">
                                                <div className="col s12">
                                                    {
                                                        Object.keys(scheduleToGo)
                                                            .map(day => ({
                                                                day,
                                                                ...scheduleToGo[day] // Copia inmutable de los valores
                                                            }))
                                                            .sort((a, b) => a.order - b.order) // Orden correcto por `order`
                                                            .map(({ day, open, close, closed, order }, i) => ( // Mapeo para renderizado
                                                                <div key={order} className={i % 2 === 0 ? 'grey lighten-4' : ''}>
                                                                    <h5>{day.charAt(0).toUpperCase() + day.slice(1)}</h5>
                                                                    <p>
                                                                        <label>
                                                                            <span className={closed ? 'red-text darken-4 boldText' : 'boldText'}>Cerrado</span>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={closed}
                                                                                onChange={(e) => handleChangeToGo(day, "closed", e.target.checked)}
                                                                            />
                                                                        </label>
                                                                    </p>
                                                                    {!closed && (
                                                                        <>
                                                                            <label>
                                                                                Hora de apertura:
                                                                                <input
                                                                                    type="time"
                                                                                    value={open}
                                                                                    onChange={(e) => handleChangeToGo(day, "open", e.target.value)}
                                                                                />
                                                                            </label>
                                                                            <label>
                                                                                Hora de cierre:
                                                                                <input
                                                                                    type="time"
                                                                                    value={close}
                                                                                    onChange={(e) => handleChangeToGo(day, "close", e.target.value)}
                                                                                />
                                                                            </label>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ))
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>

                            </div>

                        </div>

                        : null

                }

                <div className="col s12">
                    <div className="divider" />
                </div>
            </div>

            <input type="file" hidden id="imgFile"

                onChange={(e) => {
                    if (e.target.files.length) {
                        setSettings({ ...settings, img: e.target.files[0] })
                    } else {
                        setSettings({ ...settings, img: false });
                    }
                }}
            />
        </div>
    )
}


export default Configs;