import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, getFirestore } from 'firebase/firestore';
import * as utils from '../utils';
import * as pos from '../firebase_utils/pos';
import { Link } from "react-router-dom";
import iconWhats from './assets/whatsicon.png';
import ModalAlert from '../commons_components/modalAlert';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [commands, setCommands] = useState([]);
  const [loadingId, setLoadingId] = useState(false);
  const [dataAlert, setDataAlert] = useState(false);

  const saveOrder = (ord) => {

    setDataAlert({
      message: `¿Está seguro de entregar la orden? `,
      actionConfirm: () => {
        outFunctionSaveOrder(ord);
      }
    });

  }

  const outFunctionSaveOrder = async (ord) => {

    setLoadingId(ord.id);

    if (await pos.addOrder(ord)) {
      if (!ord.command) {
        await pos.deleteOrderOnline(ord);
      }
    }
    setLoadingId(false);

  }

  const deleteOrder = (ord) => {

    setDataAlert({
      message: `¿Está seguro de eliminar la orden? `,
      text: 'Al hacer esto no se podra recuperar la orden',
      actionConfirm: () => {
        outFunctionDeleteOrder(ord);
      }
    });

  }

  const outFunctionDeleteOrder = async (ord) => {

    setLoadingId(ord.id);


    await pos.deleteOrderOnline(ord);

    setLoadingId(false);

  }

  useEffect(() => {
    const db = getFirestore();

    // Colecciones separadas para órdenes y comandos
    const ordersCollection = collection(db, 'ordersOnline');
    const commandsCollection = collection(db, 'commands');

    const unsubscribeOrders = onSnapshot(ordersCollection, (snapshot) => {
      const ordersList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setOrders(ordersList.sort((a, b) => a.timestamp - b.timestamp));
    });

    const unsubscribeCommands = onSnapshot(commandsCollection, (snapshot) => {
      const commandsList = snapshot.docs.map(doc => ({
        id: doc.id,
        command: true,
        ...doc.data(),
      }));

      setCommands(commandsList);
    });

    // Limpia las suscripciones cuando el componente se desmonta
    return () => {
      unsubscribeOrders();
      unsubscribeCommands();
    };
  }, []);

  const divideArray = (arreglo, tamañoSubarreglo) => {
    const resultado = [];

    for (let i = 0; i < arreglo.length; i += tamañoSubarreglo) {
      resultado.push(arreglo.slice(i, i + tamañoSubarreglo));
    }

    return resultado;
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className="col s1 left-align">
          <Link to='/admin' className="black-text cursorPointer">
            <h4><i className="material-icons">arrow_back</i></h4>
          </Link>
        </div>
        <div className='col s10 left-align'>
          <h3>Ordenes</h3>
        </div>
        <div className='col s12 left-align'>
          <p><i className='material-icons left'>store</i> ** Recoger en Tienda</p>
          <p><i className='material-icons left'>airport_shuttle</i> ** Enviar a Domicilio</p>
          <p><i className='material-icons left'>restaurant_menu</i> ** En Sitio</p>
        </div>
        <div className='col s12'>
          <div className='divider' />
        </div>
      </div>
      {
        divideArray([].concat(orders).concat(commands), 3).map((os, i) => {

          return <div className='row' key={i}>
            {os.map((order) => (
              <div className='col s12 m12 l4' key={order.id} id={`ORDER_${order.id}`}>
                <div className='row'>

                  <div className='col s12'>
                    <ul className="collection">
                      <li className="collection-item" >
                        {
                          order.hasOwnProperty('contactOrder') ?
                            <span className='boldText'>{order.contactOrder ? order.contactOrder.name : ""} </span>
                            :
                            <span className='boldText'>Mesa: {order.table} </span>

                        }
                        <span className='secondary-content'>

                          {
                            order.hasOwnProperty('typeDeliver') && order.typeDeliver === 'P' ?
                              <i className='material-icons right'>airport_shuttle</i> : null
                          }
                          {
                            order.hasOwnProperty('typeDeliver') && order.typeDeliver === 'S' ?
                              <i className='material-icons right'>store</i> : null
                          }
                          {
                            order.command ?
                              <i className='material-icons right'>restaurant_menu</i> : null
                          }
                        </span></li>
                      {
                        !order.command ?
                          <li className='collection-item'>
                            <button className='btn waves-effect btn-large' style={{ width: '100%' }}
                              disabled={loadingId === order.id}
                              onClick={() => {
                                saveOrder(order);
                              }}
                            >
                              <i className='material-icons'>notifications_active</i>
                            </button>
                          </li> : null
                      }



                      <li className="collection-item">
                        <i className='material-icons left'>access_time</i> {utils.formatDate(order.timestamp)}

                        <span className='secondary-content'><Link to={`/${order.command ? 'editCommand_fo' : 'editOrder'}/${order.id}`} className='material-icons waves-effect'>edit</Link></span>
                      </li>
                      {
                        order.items.map((i, idx) => {
                          return (
                            <li className="collection-item" key={idx}>
                              <div className='row'>
                                <div className='col s12 left-align'>
                                  {i.name}
                                </div>
                                {
                                  i.indications && i.indications.trim() !== "" ?
                                    <div className='col s12 lef-align boldText'>
                                      {i.indications}
                                    </div> : null
                                }
                              </div>
                            </li>
                          )
                        })
                      }
                      {
                        order.indications && order.indications.trim() !== "" ?
                          <li className="collection-item">

                            <p>Indicaciones:</p>
                            <p className='boldText'>{order.indications || ""}</p>
                          </li> : null
                      }
                      {
                        order.hasOwnProperty('contactOrder') ?
                          <React.Fragment>
                            <li className='collection-item'>
                              <a href={`tel:${order.contactOrder.phone}`} className='green-text cursorPointer'
                              ><i className='material-icons left'>local_phone</i> Llamar </a>
                            </li>
                            <li className='collection-item'>
                              <a aria-label="Chat on WhatsApp" href={`https://wa.me/+521${order.contactOrder.phone}`}
                                target='_blank'
                                rel="noreferrer"
                              >
                                <img alt="Chat on WhatsApp" src={iconWhats} style={{ maxHeight: '35px' }} />
                              </a>
                            </li>
                          </React.Fragment>
                          : null
                      }

                      {
                        order.address && order.address.location && order.typeDeliver === 'P' ?
                          <li className='collection-item'>
                            <div className='row'>
                              <div className='col s12'>
                                <a aria-label="Chat on WhatsApp" href={`https://www.google.com/maps?q=${order.address.location[0]},${order.address.location[1]}`}
                                  target='_blank'
                                  rel="noreferrer"
                                  style={{ textDecoration: 'none' }}
                                >
                                  <i className='material-icons left'>location_on</i>Ubicación
                                </a>
                              </div>
                              {
                                order.contactOrder.references ?
                                  <div className='col s12 boldText' style={{ marginTop: '10px' }}>
                                    {order.contactOrder.references}
                                  </div>
                                  : null
                              }
                            </div>

                          </li> : null
                      }

                      {
                        !order.command ?
                          <li className='collection-item'>
                            <button className='btn waves-effect btn-large red' style={{ width: '100%' }}
                              disabled={loadingId === order.id}
                              onClick={() => {
                                deleteOrder(order);
                              }}
                            >
                              <i className='material-icons'>clear</i>
                            </button>
                          </li> : null
                      }

                    </ul>
                  </div>
                </div>
              </div> // Ajusta para que coincida con los datos de tu orden
            ))}
          </div>
        })
      }
      <ModalAlert dataAlert={dataAlert} setDataAlert={setDataAlert} />
    </div>
  );
};

export default Orders;
