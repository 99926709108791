import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import M from 'materialize-css';
import { NumericFormat } from "react-number-format";
import * as fb from '../firebase_utils';
import * as utils from '../utils';

export default function Bills() {

    const [amountBill, setAmountBill] = useState('');
    const [concept, setConcept] = useState('');
    const [preview, setPreview] = useState(false);
    const [file, setFile] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());
    const [bills, setBills] = useState([]);

    const months = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    useEffect(() => {
        M.FormSelect.init(document.querySelectorAll('select'), {});
        M.updateTextFields();
        M.Modal.init(document.getElementById('formFactura'), {

            onCloseEnd: () => {
                setConcept('');
                setAmountBill('');
                setPreview(false);
                setFile(null);
            }

        });
        getBillsInit();
    }, [])

    useEffect(() => {
        if (bills.length) {
            M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});
        }
    }, [bills])


    const getBillsInit = async () => {
        console.log(selectedMonth);
        setBills(await fb.fetchBills(selectedMonth, year))
    }


    const saveBill = async () => {

        setLoading(true);
        try {
            await fb.addBill({
                amountBill: amountBill,
                concept,
                img: file
            })

            setAmountBill('');
            setPreview(false);
            setConcept('');
            setFile(null);
            setLoading(false);
            M.Modal.getInstance(document.getElementById('formFactura')).close();
        } catch (error) {
            setLoading(false);
        }
    }


    const handleMonthChange = async (e) => {
        const month = parseInt(e.target.value, 10);
        setSelectedMonth(month);
        setBills(await fb.fetchBills(month, year));
    };

    // Maneja el cambio de año
    const handleYearChange = async (e) => {
        const newYear = parseInt(e.target.value, 10);
        setYear(newYear);
        setBills(await fb.fetchBills(selectedMonth, newYear));
    };




    return (
        <div className="container">
            <div className="row">
                <div className="col s1 left-align">
                    <Link to='/admin' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className="col s11 ">
                    <h4>Facturas</h4>
                </div>
                <div className="col s12">
                    <div className="divider"></div>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m4">
                    <label>Mes</label>
                    <select value={selectedMonth}
                        id="month"
                        onChange={handleMonthChange}>
                        {months.map((month, index) => (
                            <option key={index} value={index}>{month}</option>
                        ))}
                    </select>

                </div>
                <div className="input-field col s12 m4" style={{ marginTop: '1.5rem' }}>
                    <input
                        id="year"
                        type="number"
                        value={year}
                        onChange={handleYearChange}
                        placeholder="Año"
                        min="2000"
                        max={new Date().getFullYear()}
                    />
                    <label htmlFor="year">Año</label>
                </div>
                <div className="col s12 m4">
                    <button className="btn-flat waves-effect"
                        onClick={() => {
                            M.Modal.getInstance(document.getElementById('formFactura')).open();
                        }}
                    >
                        <i className="material-icons left">add</i> Agregar Factura</button>
                </div>
                <div className="col s12">
                    <div className="divider" />
                </div>
                <div className="col s12">
                    <h5>Resultados para {months[selectedMonth]} {year}</h5>
                    <ul>
                        {bills.length > 0 ? (
                            bills.map((bill) => (
                                <li key={bill.id}>
                                    <div className="row">
                                        <div className="col s12">
                                            <div className="row">

                                                <div className="col s6">
                                                    <div className="row">
                                                        <div className="col s12">
                                                            <label>{utils.formatTimestamp(bill.date)}</label>
                                                        </div>
                                                        <div className="col s12 boldText">
                                                            {bill.concept}
                                                        </div>
                                                        <div className="col s12">
                                                            ${utils.parseMoney(parseFloat(bill.amountBill).toFixed(2))}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col s6">
                                                    {
                                                        bill.imgPre.trim() !== '' ?
                                                            <>
                                                                <img class="materialboxed" width="50" src={bill.imgPre} id={bill.id} hidden></img>
                                                            </>
                                                            : null
                                                    }
                                                </div>

                                            </div>

                                        </div>


                                        <div className="col s12">
                                            <div className="divider" />
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <p>No hay facturas para este mes.</p>
                        )}
                    </ul>
                </div>
            </div>


            <div id="formFactura" className="modal">
                <div className="modal-content">
                    <h4>Agregar Factura</h4>
                    <div className="divider"/>
                    <div className="row mt-1" >
                        <div className="col s12 m4">
                            <div className="row">
                                <div className="col s12 center-align">

                                    {
                                        preview ?
                                            <img src={preview} className="responsive-img"
                                                onClick={() => {
                                                    document.getElementById('receipt').click();
                                                }}
                                            />
                                            :

                                            <i className="material-icons cursorPointer mt-4"
                                                onClick={() => {
                                                    document.getElementById('receipt').click();
                                                }}
                                            >image</i>
                                    }


                                    <p>Recibo, Ticket</p>



                                    <input type="file" id="receipt" hidden
                                        onChange={(e) => {
                                            if (e.target.files.length) {
                                                setFile(e.target.files[0]);
                                                setPreview(URL.createObjectURL(e.target.files[0]))
                                            }
                                        }}
                                    ></input>
                                </div>

                            </div>
                        </div>
                        <div className="col s12 m8">
                            <div className="row">
                                <div className="input-field col s12">
                                    <NumericFormat
                                        id="amountBill"
                                        thousandSeparator={','}
                                        decimalSeparator="."
                                        value={amountBill}
                                        isNumericString
                                        decimalScale={2}
                                        fixedDecimalScale
                                        isAllowed={utils.isValueAllowed}
                                        prefix="$"
                                        allowNegative={false}
                                        onValueChange={(values) => {
                                            setAmountBill(values.value)
                                        }}
                                    />
                                    <label htmlFor="amountBill">Monto:</label>
                                </div>
                                <div className="input-field col s12">
                                    <input type="text" id="concept" onChange={(e) => {
                                        setConcept(e.target.value)
                                    }} value={concept} />
                                    <label htmlFor="concept">Concepto:</label>
                                </div>
                                <div className="col s12 center-align">
                                    <button className="btn-flat waves-effect waves-red waves-light"
                                        disabled={loading}
                                        onClick={() => {
                                            M.Modal.getInstance(document.getElementById('formFactura')).close();
                                        }}
                                    ><i className="material-icons right " >clear</i>Cancelar</button>
                                    <button className="btn-flat waves-green waves-effect waves-light"
                                        disabled={loading || amountBill.trim() === '' || concept.trim() === ''}

                                        onClick={() => {
                                            saveBill();
                                        }}
                                    ><i className="material-icons right">save</i>Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}