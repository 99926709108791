import * as utils from '../utils';
import { Link, useNavigate } from 'react-router-dom';
import * as loginUtils from '../firebase_utils/login';

export default function Dashboard() {

    const navigate = useNavigate();

    return (
        <div className="container">
            {/* <div className="row">
                <ul className="collection">
                    <li className='collection-item center-align'>MENÚ</li>
                    {
                        utils.MENU_ADMIN.map((v, i) => {
                            return <Link to={v.link} key={i} className="collection-item">{v.title}
                                <i className='material-icons left'>{v.icon}</i>
                            </Link>
                        })
                    }
                    <li className='collection-item cursor_pointer'
                        onClick={() => {
                            if (window.confirm('¿Está seguro de terminar su sesión?')) {
                                loginUtils.logoutFunction();
                            }
                        }}
                    >
                        <i className='material-icons left'>exit_to_app</i> Terminar Sesión
                    </li>
                </ul>
            </div> */}

            <div className='row'>
                <div className='col s12 center-align'>
                    <h3>Menú</h3>
                </div>
                <div className='col s12'>
                    <div className='divider' />
                </div>

                <div className='col s12 mt-1'>

                </div>

                {
                    utils.MENU_ADMIN.map((v, i) => {
                        return <div className='col s6 m4 center-align' key={i}>
                            <div className='row waves-effect optMenu'
                             onClick={() => {
                                navigate(v.link)
                             }}
                            >
                                <div className='col s12 '>
                                    <i className='material-icons ft-40px'>{v.icon}</i>
                                </div>
                                <div className='col s12 boldText'>
                                    {v.title}
                                </div>
                            </div>
                        </div>
                    })
                }
                <div className='col s6 m4 center-align'>
                    <div className='row waves-effect optMenu' 
                        onClick={() => {
                            if (window.confirm('¿Está seguro de terminar su sesión?')) {
                                loginUtils.logoutFunction();
                            }
                        }}
                    >
                        <div className='col s12'>
                            <i className='material-icons ft-40px'>exit_to_app</i>
                        </div>
                        <div className='col s12 boldText'>
                            Terminar Sesión
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};