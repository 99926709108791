import { useEffect } from 'react';
import { collection, onSnapshot, getFirestore } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { setProducts, updateProduct, addProduct, deleteProduct } from '../redux/slices/appSlice';
import * as fb from '../firebase_utils';


const useProducts = () => {
  const dispatch = useDispatch();

  useEffect(() => {

    const db = getFirestore();

    const unsubscribe = onSnapshot(collection(db, 'products'), async (snapshot) => {

      snapshot.docChanges().forEach(async (change) => {
        let product = { id: change.doc.id, ...change.doc.data() };

        if (change.type !== 'removed' && product.img) {
          try {
            const url = await fb.getImageUrl(product.img);
            product.img = url;
          } catch (error) {

          }
        }
    
        if (change.type === 'added') {
          dispatch(addProduct(product));
        }
        if (change.type === 'modified') {
          dispatch(updateProduct(product));
        }
        if (change.type === 'removed') {
          dispatch(deleteProduct(product.id));
        }
      });


      const products = [];

      for (let doc of snapshot.docs) {

        let data = doc.data();
        data.id = doc.id;

        try {
          if (data.img) {
            const url = await fb.getImageUrl(data.img);
            data.img = url;
          }
        } catch (error) {

        }
        products.push(data);
      }
      
      dispatch(setProducts(products));
    });

    return () => unsubscribe(); // Cleanup al desmontar
  }, [dispatch]);
};

export default useProducts;