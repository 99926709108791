import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as utils from '../utils';
import * as fb from '../firebase_utils';
import { useSelector } from "react-redux";
import {
    DndContext,
    closestCenter,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


const SortableItem = ({ v, i }) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: v.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '10px',
        margin: '5px',
        backgroundColor: isDragging  ?  '#e0e0e0':'white',
        cursor: 'grab',
        zIndex: isDragging ? 100 : 'auto'
    };

    return (
        <div className="col s12" ref={setNodeRef} style={style}>
            <div className="row">
                <div className="col s1 left-align boldText"  {...attributes} {...listeners}>
                    {i+1}
                </div>
                <div className="col s4 left-align truncate"  {...attributes} {...listeners}>
                    {v.name}
                </div>
                <div className="col s3 center-align" {...attributes} {...listeners}>
                    {v.active ? 'SI' : 'NO'}
                </div>
                <div className="col s4 center-align" id="botones">
                    <Link to={`/categorie/${v.id}`}
                        className="material-icons cursor_pointer green-text"
                        >edit</Link>
                    <i className="material-icons cursor_pointer red-text"
                        onClick={async () => {
                            console.log("HAce click");
                            if (window.confirm('¿Estás seguro de eliminar esta categoría?')) {

                                await fb.deleteCategorie(v.id)
                            }

                        }}
                    >delete</i>
                </div>

                <div className="col s12">
                    <div className="divider"/>
                </div>
            </div>

        </div>
    );
};

export default function Categories() {

    const appData = useSelector((state) => state.appData);

    const [categories, setCategories] = useState([])

    useEffect(() => {
        utils.initFloatButton();
    }, []);

    useEffect(() => {

        if (appData.categories && appData.categories.length ) {
            setCategories(appData.categories);
        }
    }, [appData]);


    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setCategories((categories) => {
                // Clonar y ordenar el array actual por el campo 'order'
                const sortedItems = [...categories].sort((a, b) => a.order - b.order);

                // Encontrar el índice antiguo y el nuevo
                const oldIndex = sortedItems.findIndex(categorie => categorie.id === active.id);
                const newIndex = sortedItems.findIndex(categorie => categorie.id === over.id);

                // Mover el elemento y actualizar su nuevo orden
                const newArray = arrayMove(sortedItems, oldIndex, newIndex).map((categorie, index) => ({
                    ...categorie,
                    order: index // Asignar el nuevo orden a todos los elementos
                }));

                fb.updateCategorieOrder(newArray);

                return newArray;
            });
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col s1 left-align">
                    <Link to='/admin' className="black-text cursorPointer">
                        <h4><i className="material-icons">arrow_back</i></h4>
                    </Link>
                </div>
                <div className="col s11 left-align">
                    <h4>Categorías</h4>
                </div>
                <div className="col s12">
                    <label>Orden: Es el orden del menú</label>
                </div>
                <div className="col s12">
                    <div className="divider"></div>
                </div>
            </div>

            <div className="row">

                <div className="col s12">
                    <div className="row">
                    <div className="col s1 boldText truncate">
                            Orden
                        </div>
                        <div className="col s4 boldText">
                            Nombre
                        </div>
                        <div className="col s3 center-align boldText">
                            Activo
                        </div>
                        <div className="col s4 center-align boldText">
                            Acción
                        </div>
                    </div>
                </div>

                <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} >
                    <SortableContext items={categories} strategy={verticalListSortingStrategy}>
                        {categories.map((item, index) => (
                            <SortableItem key={index} v={item} i={index} />
                        ))}
                    </SortableContext>
                </DndContext>

            </div>


            <div className="fixed-action-btn">
                <Link to="/addCategorie" className="btn-floating btn-large red">
                    <i className="large material-icons waves-effect">
                        add
                    </i>
                </Link>
            </div>
        </div>
    )
}