import {getFirestore, collection, addDoc, getDocs, deleteDoc, doc, getDoc, updateDoc, query, where } from 'firebase/firestore';
import M from 'materialize-css';
import * as fb_utils from './index';

export const  addOrder = async (dataOrder)=>{
    const db = getFirestore();  

    try {
       dataOrder.timestamp = new Date().getTime();
       await addDoc(collection(db,`/orders`),dataOrder);
       M.toast({ html: "Información almacenada correctamente" , classes:"green darken-4"});
       return true;
    } catch (error) {
   
        M.toast({ html: "Ocurrio un error al guardar la información." });
        return false;
    }
}   

export const  addOrderOnline = async (dataOrder)=>{
    const db = getFirestore();  

    try {
       dataOrder.timestamp = new Date().getTime();

       dataOrder = await fb_utils.restInventoryTemporal(dataOrder);


       await addDoc(collection(db,`/ordersOnline`),dataOrder);
       M.toast({ html: "Información almacenada correctamente" , classes:"green darken-4"});
       return true;
    } catch (error) {
      
        M.toast({ html: "Ocurrio un error al guardar la información." });
        return false;
    }
}   

export const getOrdersByDay = async (start, end) =>{
    
    const db = getFirestore(); 

    if(!start){
        start = new Date();
    }

    let q =  query(collection(db,`orders`), where("timestamp", ">=", start.getTime()));

    if(end){
        q = query(q, where("timestamp", "<=", end.getTime()));
    }

    try {
       const snaps = await getDocs(q);
       if(snaps.empty){
        return [];
       }else{
         let arr = [];
         for (const doc of snaps.docs) {
            let data = doc.data();
            data.id = doc.id;
            arr.push(data);
         }
         return arr;
       }
        
    } catch (error) {
        
        return [];
    }
}

//Comandas

export const  addComand = async (dataOrder)=>{
    const db = getFirestore();  

    try {


       dataOrder = await fb_utils.restInventoryTemporal(dataOrder);

       dataOrder.timestamp = new Date().getTime();
       const docRef = await addDoc(collection(db,`/commands`),dataOrder);
       M.toast({ html: "Información almacenada correctamente" , classes:"green darken-4"});

       dataOrder.id = docRef.id;
    
       return dataOrder;
    } catch (error) {
       
        M.toast({ html: "Ocurrio un error al guardar la información." });
        return false;
    }
} 

export const  getCommand = async (idCommand)=>{
    const db = getFirestore();  
    try {
       const snap = await getDoc(doc(db,'/commands',idCommand));

       if(snap.exists()){
         return snap;
       }else{
        M.toast({ html: "La comanda ya no se encuentra o no existe." });
        return false;
       }
    } catch (error) {
        return false;
    }
}  

export const  updateCommand = async (dataProduct,idCommand)=>{
    const db = getFirestore();  
    
    try {
  
       dataProduct =  await fb_utils.restInventoryTemporal(dataProduct);

       await updateDoc(doc(db,'/commands',idCommand),dataProduct)
       M.toast({ html: "Información actualizada correctamente" , classes:"green darken-4"});

     

       return dataProduct;
    } catch (error) {
      
        M.toast({ html: "Ocurrio un error al actualizar la información." });
        return false;
    }
}  





export const  getOrderOnline = async (idCommand)=>{
    const db = getFirestore();  
    try {
       const snap = await getDoc(doc(db,'/ordersOnline',idCommand));

       if(snap.exists()){
         return snap;
       }else{
        M.toast({ html: "La comanda ya no se encuentra o no existe." });
        return false;
       }
    } catch (error) {
        return false;
    }
}  

export const  updateOrderDeliver = async (dataOrder, idOrder)=>{
    const db = getFirestore();  

    dataOrder.deliverAt = new Date().getTime();
    try {
       await updateDoc(doc(db,'/orders',idOrder),dataOrder)
       M.toast({ html: "Información actualizada correctamente" , classes:"green darken-4"});
       return true;
    } catch (error) {
       
        M.toast({ html: "Ocurrio un error al actualizar la información." });
        return false;
    }
}  


export const  updateOrderOnline = async (dataProduct,idCommand)=>{
    const db = getFirestore();  
    
    try {
       dataProduct =  await fb_utils.restInventoryTemporal(dataProduct);
       await updateDoc(doc(db,'/ordersOnline',idCommand),dataProduct)
       M.toast({ html: "Información actualizada correctamente" , classes:"green darken-4"});
       return dataProduct;
    } catch (error) {
      
        M.toast({ html: "Ocurrio un error al actualizar la información." });
        return false;
    }
}  

export const  deleteOrderOnline = async (dataOrder)=>{
    const db = getFirestore();  
    try {
       dataOrder.timestamp = new Date().getTime();

       //Recupera el inventario
       for (let p of dataOrder.items) {
        await fb_utils.updateInventoryNoHistoric(p.id, 1, 'agregado');
       }

       await deleteDoc(doc(collection(db,`/ordersOnline`),dataOrder.id));
       return true;
    } catch (error) {
   
        M.toast({ html: "Ocurrio un error al eliminar la información." });
        return false;
    }
}   

export const  deleteCommand = async (dataOrder)=>{
    const db = getFirestore();  
    try {
       dataOrder.timestamp = new Date().getTime();
       await deleteDoc(doc(collection(db,`/commands`),dataOrder.id));
       return true;
    } catch (error) {
        
        M.toast({ html: "Ocurrio un error al eliminar la información." });
        return false;
    }
}   