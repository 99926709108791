import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';
import * as utils from '../utils';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';

function LocationMarker({ location, setLocation, setAddress }) {
  const customIcon = L.icon({
    iconUrl: markerIconPng,
    shadowUrl: markerShadowPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useMapEvents({
    click(e) {
   
      setLocation(e.latlng);
      setAddress(e.latlng);
    },
  });

  return location === null ? null : (
    <Marker position={location} icon={customIcon} />
  );
}

function OrderMap({ setAddress }) { 
  
  const [location, setLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState(utils.CENTER_INIT_MAP); // Coordenadas por defecto
  const [isCentered, setIsCentered] = useState(false); 

  function SetMapCenter({ center }) {
    const map = useMap(); // Obtiene la instancia del mapa
    useEffect(() => {
      if (center && !isCentered) {
        map.setView(center, 13); // Centra el mapa en la ubicación especificada, con un zoom de 13
        setIsCentered(true);
      }
    }, [center, map, isCentered]);
    return null; // No renderiza nada
  }



  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = [latitude, longitude];
          setMapCenter(userLocation);
          setLocation(userLocation);
          setIsCentered(false);
          setAddress(userLocation) // Colocar el marcador en la posición obtenida
        },
        (error) => {
          console.error("Error obteniendo la ubicación: ", error);
          alert("No se pudo obtener la ubicación, se usará la ubicación por defecto.");
        }
      );
    } else {
      alert("Geolocalización no es soportada por este navegador.");
    }
  }, []);// eslint-disable-next-line

  return (
    <div>
      <MapContainer
        center={mapCenter}
        zoom={14}
        style={{ height: '400px', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; OpenStreetMap contributors"
        />
        <LocationMarker location={location} setLocation={setLocation} setAddress={setAddress} />

      
        <SetMapCenter center={mapCenter} /> 
       
      </MapContainer>
    </div>
  );
}

export default OrderMap;
