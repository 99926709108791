import { useEffect, useRef, useState } from 'react';
import * as utils from '../utils';
import * as validations from '../firebase_utils/validations';
import * as fb_utils from '../firebase_utils';
import M from 'materialize-css';
import { useSelector } from 'react-redux';


export default function ModalReceipt({ receiptAlert, setReceipt }) {

    const appData = useSelector((state) => state.appData)

    const receiptRef = useRef();
    const [logo, setLogo] = useState(false);

    useEffect(() => {
        M.Modal.init(document.getElementById('modalReceipt'), {
            dismissible: false,
            onCloseStart: () => {
                setReceipt(false);
            }
        });
    }, [])

    useEffect(() => {
        if (appData.settings) {
            getLogo(appData.settings);
        }
    }, [appData]);

    useEffect(() => {
        if (receiptAlert) {
         
            M.Modal.getInstance(document.getElementById('modalReceipt')).open();
        } else {
            M.Modal.getInstance(document.getElementById('modalReceipt')).close();
        }
    }, [receiptAlert]);


    const getLogo = async (apSettings) => {
        if (apSettings) {
            let logoImg = '';
            if (apSettings.img !== "") {
                logoImg = await fb_utils.getImageLogo(apSettings.img);
                setLogo(logoImg);
            }
        }
    }

    const handlePrint = () => {
        const content = document.getElementById(`${receiptAlert.id}_receipt_ticket`).innerHTML; //receiptRef.current.innerHTML;
        const printWindow = window.open('', '', 'height=600,width=800');
    
        // Copia los estilos de la página actual a la nueva ventana
        const styles = Array.from(document.querySelectorAll('style, link[rel="stylesheet"]'))
          .map(style => style.outerHTML)
          .join('');
    
        printWindow.document.write(`
          <html>
            <head>
              <title>Imprimir Recibo</title>
              ${styles} <!-- Incluye los estilos -->
            </head>
            <body>
              ${content}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        //printWindow.close();
      };
    

    return (
        <div id="modalReceipt" className="modal">
            <div className="modal-content">
                <div className='row'>
                    <div className='col s12 right-align'>
                        <i className='material-icons modal-close'>clear</i>
                    </div>
                </div>
                <div className='container row' id={`${receiptAlert.id}_receipt_ticket`} ref={receiptRef}>
                    <div className='col s12 center-align' hidden={!logo}>
                        <img src={logo} className='responsive-img' style={{maxHeight:'60px'}} />
                    </div>
                    
                    <div className='col s12 boldText'>
                        {appData.settings && appData.settings.hasOwnProperty('name') ? appData.settings.name : '' }
                    </div>
                    <div className='col s12'>
                        {utils.formatDate(new Date(receiptAlert.timestamp).toString())}
                    </div>
                    <div className='col s12 left-algin'>
                        <label>{receiptAlert.id}</label>
                    </div>

                    <div className='col s12'>
                        <table>
                            <thead>
                                <tr>
                                    <td>Prod</td>
                                    <td></td>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    receiptAlert && receiptAlert.items.map((p) => {
                                        return (
                                            <tr className='sale_order'
                                                key={p.cbar}
                                            >
                                                <td className='truncate' style={{ maxWidth: '150px' }}>{p.quantity || 1} x {p.name}</td>
                                                <td>${utils.parseMoney((parseFloat(p.price) * (p.quantity || 1)).toFixed(2))}</td>
                                            </tr>
                                        )
                                    })
                                }
                                  {
                                    receiptAlert ? 
                                <tr>
                                    <td className='right-align'>Total:</td>
                                    <td className='left-align boldText'>${utils.parseMoney(parseFloat(validations.getTotalOrder(receiptAlert.items), receiptAlert.discount).toFixed(2))}</td>
                                </tr> : null }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div className="modal-footer">
                <button  onClick={()=>{
                    handlePrint()
                }} className="waves-effect waves-green btn-flat"><i className='material-icons right'>print</i>Imprimir</button>
            </div>
        </div>
    )
}