import { createSlice } from '@reduxjs/toolkit'


export const appSlice = createSlice({
    name: 'appData',
    initialState: {
        alertModal: false,
        categories: [],
        products: [],
        settings: false
    },
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        updateProduct: (state, action) => {

            const index = state.products.findIndex(product => product.id === action.payload.id);
            if (index !== -1) {
                state.products[index] = action.payload;
            }
        },
        addProduct: (state, action) => {
            const index = state.products.findIndex(product => product.id === action.payload.id);

            if (index === -1) {
                state.products.push(action.payload);
            }

        },
        deleteProduct: (state, action) => {
            state.products = state.products.filter(product => product.id !== action.payload.id);
        },
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        updateCategorie: (state, action) => {

            const index = state.categories.findIndex(categorie => categorie.id === action.payload.id);
            if (index !== -1) {
                state.categories[index] = action.payload;
            }
        },
        addCategorie: (state, action) => {
            const index = state.categories.findIndex(categorie => categorie.id === action.payload.id);

            if (index === -1) {
                state.categories.push(action.payload);
            }

        },
        deleteCategorie: (state, action) => {
            state.categories = state.categories.filter(categorie => categorie.id !== action.payload.id);
        },
        setSettings: (state, action) =>{
      
            state.settings = action.payload;
        },
        updateSettings: (state, action) =>{
         
            state.settings = action.payload;
        }
    },
});

export const { 
    setProducts, 
    updateProduct, 
    addProduct, 
    deleteProduct,
    setCategories,
    updateCategorie,
    addCategorie,
    deleteCategorie,
    setSettings,
    updateSettings
 } = appSlice.actions

export default appSlice.reducer;
